import React, { Component } from 'react'
import { Link } from 'react-router-dom' 
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'; 
import Collapse from "react-bootstrap/Collapse";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2"; 
import axios from "axios";
import Cookies from 'universal-cookie';
import CartEmpty from './CartEmpty';
import globalData from '../config.json'

const cookies = new Cookies();

class Cart extends Component {
  
    constructor(props) {
		super(props)

		this.state = {
			userId: '',
			subtotal: 0,
			total: 0,
			cartList: [],
			selectedList: [],
			msg:'',
			package_quantity:0,
			supplement_quantity:0,
			cartListStatus:false,
		}
		this.handleChange = this.handleChange.bind(this)
		this.removeCart = this.removeCart.bind(this)
		this.removeAllCart = this.removeAllCart.bind(this)
		this.handleScroll = this.handleScroll.bind(this);
		


	}

	gotoprevious(e){
	
		let geturl = this.state.cartList.slice(-1).pop()
		window.location.href = globalData.redirectUrl+geturl.meta.slug_event_type;
	
	}

	componentDidMount() {
		
		// window.scrollTo(0, 0);
        let scripts = [
            { src: globalData.apiUrl+"/front/js/app-html.js" }
         ]

		//  $("#dropdnMinicart").removeClass("is-opened");
        //Append the script element on each iteration
       
        scripts.forEach(item => { 
            const script = document.createElement("script")
            script.id =  "myScript";
            script.src = item.src
            script.async = true;
            document.body.appendChild(script)
        })
		// window.addEventListener("scroll", this.handleScroll);
		if(localStorage.getItem('token'))
			this.getAuth(localStorage.getItem('token'))
		else if(cookies.get('guesttoken') != '' && cookies.get('guesttoken') != undefined ){
			this.getGuestShoppingCartList()
		}
		
	}

	componentDidUpdate(prevProps, prevState) {
	
		if(prevProps.cartCount !== this.props.cartCount){
			if(localStorage.getItem('token'))
			{
				this.getShoppingCartList();
			}else{
				this.getGuestShoppingCartList()
			}
		}
		

		if (this.props.user && this.props.user != 'guest' )
			if (this.props.user.id != this.state.userId)
				this.getAuth(localStorage.getItem('token'))

		
	}

	getAuth(token) {
        axios.get(globalData.apiUrl+'/api/auth', {
            headers: { Authorization: `Bearer ${token}`}
        }).then(result => {
			this.setState({userId: result.data.user.id})

			if(localStorage.getItem('cartList'))
				this.saveToShopppingCart(localStorage.getItem('cartList'))
			else
				this.getShoppingCartList(result.data.user.id)

        }).catch(error => {
			// console.log(error)
			if(localStorage.getItem('cartList') !== null)
				this.getGuestShoppingCartList(localStorage.getItem('cartList'))
		})
    }

	getShoppingCartList() {

		this.setState({loading: true})

		axios.get(globalData.apiUrl+'/api/cart', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
        }).then(( response ) => {
			if(typeof response.data.data.cartList !== "undefined" && response.data.data.cartList.length > 0)
			{
				this.setState({
					cartList: [...response.data.data.cartList],
					subtotal: response.data.data.total,
					total: response.data.data.totalPayableAmount,
					cartListStatus: true
				})
				this.props.updateCartCount(this.state.cartList.length);
				localStorage.setItem('cart_count', this.state.cartList.length)
			}else{
				this.setState({
					cartList: [],
					subtotal: 0,
					total: 0,
					cartListStatus: true,
				  });
				  this.setState({ cartItemCount: 0 });
				  this.props.updateCartCount(this.state.cartItemCount);
			}


        }).catch(function (error) {
			if(error.response.status == 404){
				this.setState({
					cartList: [],
					subtotal: 0.00,
					total: 0.00,
					cartListStatus: true,
					msg: error.response.data.message,
				})
			}
            // console.log(error)
        })
	}

	getGuestShoppingCartList(){
		
		// console.log(cookies.get('guesttoken'));

		axios.get(globalData.apiUrl+`/api/cart?paramId=${cookies.get('guesttoken')}`, {
    
        }).then(( response ) => {
			if(typeof response.data.data.cartList !== "undefined" && response.data.data.cartList.length > 0)
			{
				this.setState({
					cartList: [...response.data.data.cartList],
					subtotal: response.data.data.total,
					total: response.data.data.totalPayableAmount,
					cartListStatus: true
				})
				this.props.updateCartCount(this.state.cartList.length);
				localStorage.setItem('cart_count', this.state.cartList.length)
			}else{
				this.setState({
					cartList: [],
					subtotal: 0,
					total: 0,
					cartListStatus: true,
				  });
				  this.setState({ cartItemCount: 0 });
				  this.props.updateCartCount(this.state.cartItemCount);
			}

        }).catch(function (error) {
            //  console.log(error)
        })
	}

	handleChange(id, event) {
	
		const {name, value} = event.target
	
		if(cookies.get('guesttoken') != undefined && localStorage.getItem('token') == undefined && value != ''){
			axios.put(globalData.apiUrl+`/api/cart`,
			{
			  id: id,
			  quantity: value,
			  paramId:cookies.get('guesttoken'),
			}).then(( response ) => {
				if(response.data.data.length > 0){
				// console.log(response.data.data);
				this.setState({
					cartList: [...response.data.data.cartList],
					subtotal: response.data.data.total,
					total: response.data.data.totalPayableAmount,
					cartListStatus: true
				})
				localStorage.setItem('cart_count', this.state.cartList.length)
				}else{
					Swal.fire(response.data.message, "", "warning");
			  	}

			}).catch(function (error) {
				//  console.log(error.response.data)
			})
			// console.log(event);
		}else if(localStorage.getItem('token') != undefined && value != ''){

			let config = {
				headers: {
				  'Content-Type': 'application/json',
				  Authorization:  `Bearer ${localStorage.getItem('token')}`,
				}
			}
			// console.log('fffffffffffffffffffffffffffffffffffffff');
			axios.put(globalData.apiUrl+`/api/cart`, {
				id: id,
				quantity: value,
			},
			config,
			).then(( response ) => {
				// console.log(response.data.data);
				if(response.data.data.length > 0){
					this.setState({
						cartList: [...response.data.data.cartList],
						subtotal: response.data.data.total,
						total: response.data.data.totalPayableAmount,
						cartListStatus: true
					})
					localStorage.setItem('cart_count', this.state.cartList.length)
				}else{
					Swal.fire(response.data.message, "", "warning");
				}
			}).catch(function (error) {
				//  console.log(error.response.data)
			})
			// console.log(event);
		}
	}

	removeAllCart(event){
		Swal.fire({  
			title: 'Are you sure?',  
			text: 'You want to remove all items ?',  
			icon: 'warning',  
			showCancelButton: true,  
			confirmButtonColor: '#3085d6',  
			cancelButtonColor: '#d33',  
			confirmButtonText: 'Yes!'  
		  }).then((confirmed) => {
			  // console.log(confirmed);
			  if (confirmed.value) {
				if(localStorage.getItem('token')){
					axios.delete(globalData.apiUrl+`/api/cart/`, {
						headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
					}).then(( response ) => {
					
						this.setState({
							cartList: [],
							subtotal: '',
							total: '',
							cartListStatus: true
						})
						// window.location.reload()
						this.props.updateCartCount(0);
						Swal.fire({
							title:response.data.message,
							showConfirmButton: false,
							icon:"success",
							timer: 3000,
							timerProgressBar: true,
						  });
						// Swal.fire(response.data.message, '', 'success');
			
					}).catch(function (error) {
						// console.log(error)
					})
				}else{
					axios.delete(globalData.apiUrl+`/api/cart?paramId=${cookies.get('guesttoken')}`, {
					
					}).then(( response ) => {
					
						this.setState({
							cartList: [],
							subtotal: '',
							total: '',
							cartListStatus: true
						})
						// window.location.reload()
						// this.handleScroll()
						this.props.updateCartCount(0);
						Swal.fire({
							title:response.data.message,
							showConfirmButton: false,
							icon:"success",
							timer: 3000,
							timerProgressBar: true,
						  });
						// Swal.fire(response.data.message, '', 'success');
			
					}).catch(function (error) {
						// console.log(error)
					})
				}
			}
			else {
				Swal.fire('You cancel', '', 'error');
			}
			
		});
	}

	removeCart(id, event){
		Swal.fire({  
			title: 'Are you sure?',  
			text: 'You want To remove ?',  
			icon: 'warning',  
			showCancelButton: true,  
			confirmButtonColor: '#3085d6',  
			cancelButtonColor: '#d33',  
			confirmButtonText: 'Yes!'  
		  }).then((confirmed) => {
			  // console.log(confirmed);
			  if (confirmed.value) {
				if(localStorage.getItem('token')){
					axios.delete(globalData.apiUrl+`/api/cart/${id}`, {
						headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
					}).then(( response ) => {
					
						this.getShoppingCartList()
						
						Swal.fire({
							title:response.data.message,
							showConfirmButton: false,
							icon:"success",
							timer: 3000,
							timerProgressBar: true,
						  });
						// Swal.fire(response.data.message, '', 'success');
			
					}).catch(function (error) {
						// console.log(error)
					})
				}else{
					axios.delete(globalData.apiUrl+`/api/cart/${id}?paramId=${cookies.get('guesttoken')}`, {
					
					}).then(( response ) => {
					
						this.getGuestShoppingCartList()
						// this.handleScroll()
						Swal.fire({
							title:response.data.message,
							showConfirmButton: false,
							icon:"success",
							timer: 3000,
							timerProgressBar: true,
						  });
						// Swal.fire(response.data.message, '', 'success');
			
					}).catch(function (error) {
						// console.log(error)
					})
				}
			}
			else {
				Swal.fire('You cancel', '', 'error');
			}
			
		});
	}

	handleScroll(){
		// THEME.header.stickyHeaderInit('.hdr-content-sticky')
	}

   render() {
	//    console.log(this.state.cartListStatus);
     	if(this.state.cartListStatus){
			return (
				<>
				{
					(this.state.cartList && this.state.cartList.length > 0) ? 
					<div className="page-content">
						<div id="cart-page" className="bg-dark">
							<section className="shopping-cart dark">
							<div className="container">
								<div className="block-heading">
								<h1>MY BASKET</h1>
								</div>
								<div className="content">

								<div className="row">
									
									<div className="col-md-12 col-lg-8">
									<div className="items">
										{this.state.cartList.map((ct, index) => {
											return(
											<div className="product" key={ct.id}>
											<div className="row">
												<div className="col-md-2">
												<img className="img-fluid mx-auto d-block image" src={ct.eventImageThumbnail} />
												</div>
												<div className="col-md-10">
												<div className="info">
													<div className="row">
													<div className="col-md-5 product-name">
														<div className="product-name">
															<a href="#">Package Description</a>
															<div className="product-info">
																<div>{ct.packageName}</div>
															</div>
														</div>
														{ct.meta.is_installments == 1 ?
															<div className="product-name"> <a href="#">Instalment</a>
																<div className="product-info">
																	<div>{ ReactHtmlParser (ct.installmentsData.installmentsMessage)}</div>
																</div>
															</div>
															: ''
														}
														
													</div>
													<div className="col-md-2 quantity ">
														<label htmlFor="quantity">Quantity</label>
														<input type="number" className="form-control select-width" name="quantity" onChange={(e) => this.handleChange(ct.id,e)} defaultValue={ct.quantity} />
														
													</div>
													<div className="col-md-2">
														<div className="product-name">
														<a href="#">Price</a>
														<div className="product-info">
															<div className="price">£{ct.unit_price} </div>
														</div>
														</div>
														
													</div>
													<div className="col-md-2">
														<div className="product-name">
														<a href="#">Subtotal</a>
														<div className="product-info">
															<div className="price">£{ct.total_price} </div>
														</div>
														</div>
															
													</div>
													<div className="col-md-1 d-flex justify-content-center align-items-center">
														<div className="product-name">
														<div className="product-info">
															<div className="price"><a style={{cursor: "pointer" }} onClick={(e) => this.removeCart(ct.idEncrypt,e)}><i className="icon-recycle" style={{fontSize: 20}} /></a></div>
														</div>
														</div>
													</div>
													</div>
												</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-2">
												
												</div>
												<div className="col-md-10">
												<div className="info">
													<div className="row">
													<div className="col-md-5 product-name">
														
														{ct.supplements_status == 1 ?
															<div className="product-name"> <a href="#">Supplements</a>
																<div className="product-info">
																	<div>{ct.cartItemSupplement.supplementsData.supplements_p1}
																	&nbsp;&nbsp; {ct.cartItemSupplement.supplementsData.supplements_p2}
																	&nbsp;{ct.cartItemSupplement.supplementsData.supplements_p3}
																	</div>
																</div>
															</div>
															: ''
														}
														{ (ct.supplements_status == 1 && ct.cartItemSupplement.supplementsData.installmentsData) ?
															<div className="product-name"> <a href="#">Instalment</a>
																<div className="product-info">
																	<div>{ ReactHtmlParser (ct.cartItemSupplement.supplementsData.installmentsData.installmentsMessage)}</div>
																</div>
															</div>
															: ''
														}
													</div>
													<div className="col-md-2 quantity ">
														{ct.supplements_status == 1 ?
															<>
														<label htmlFor="quantity" style={{ marginTop: "10px"}}>Supplement</label>
															<select defaultValue={ct.cartItemSupplement.supplementsData.selectedSupplement} onChange={(e) => this.handleChange(ct.cartItemSupplement.id,e)}  className="form-control select-width">
																{ct.cartItemSupplement.supplementsData.noOfSingleRoomSupplements.map(item => (
																		ct.cartItemSupplement.supplementsData.selectedSupplement == item.optionValue ?
																		<option value={item.optionValue } key={item.optionValue} selected>{item.optionName }</option>
																		:
																		<option value={item.optionValue } key={item.optionValue} >{item.optionName }</option>
																		
																	))}
															</select>
															</>
															: ''
														}
													</div>
													<div className="col-md-2">
														
														{ct.supplements_status == 1 ?
														<div className="product-name mt-4">
															{/* <a href="#">Price</a> */}
															<div className="product-info">
																<div className="price">£{ct.cartItemSupplement.unit_price} </div>
															</div>
														</div>
														: ' '
														}
													</div>
													<div className="col-md-2">
												
														{ct.supplements_status == 1 ?
															<div className="product-name mt-4">
																{/* <a href="#">Subtotal</a> */}
																<div className="product-info">
																	<div className="price">£{ct.cartItemSupplement.total_price} </div>
																</div>
															</div>
															: ''
														}	
													</div>
													<div className="col-md-1 d-flex justify-content-center align-items-center">
														
													</div>
													</div>
												</div>
												</div>
											</div>
											<hr />
											</div>
											)
										}
										)}
									</div>
									</div>
									
									
									<div className="col-md-12 col-lg-4">
									<div className="summary">
										<h2>Order Summary</h2>
										<div className="summary-item"><span className="text">TOTAL TOUR PRICE</span><span className="price">£{this.state.subtotal}</span></div>
										{/* <div className="summary-item"><span className="text">Discount</span><span className="price">£0</span></div>
										<div className="summary-item"><span className="text">Shipping</span><span className="price">£0</span></div> */}
										<div className="summary-item mb-2">
											{this.state.cartList[0].meta.is_installments == 1 ? (
												<span className="text">First Installment Price</span>
												) : <span className="text">Total Price</span>
												}
											<span className="price">£{this.state.total}</span></div>
										<Link to="/checkout" className="btn btn-primary btn-lg btn-block">Checkout</Link>
										<button type="button" onClick={(e) => this.gotoprevious(e)} className="btn bg-success btn-lg btn-block">Continue shopping</button>
										<button type="button" onClick={(e) => this.removeAllCart(e)} className="btn bg-danger btn-lg btn-block">Empty shopping basket</button>
									</div>
									</div>
								</div>
								</div>
							</div>
							</section>
						</div>
					</div> 
					: 
					<CartEmpty />
					// <div className="page-content">
					// 	<div id="empty-cart" className="bg-dark">
					// 		<section className="shopping-cart dark">
					// 			<div className="container">
					// 				<div className="block-heading">
					// 					<h1>MY BASKET</h1>
					// 				</div>
					// 				<div className="content">
					// 					<div className="row">
					// 						<div className="col-md-12 col-lg-12">
					// 							<div className="items" style={{ backgroundImage: "url('front/images/cart-bg.png')" }}>
					// 								<div className="container">
					// 									<div className="row">
					// 										<div className="col-md-12">
					// 											<div className="card border-0">
					// 												<div className="card-body cart">
					// 													<div className="col-sm-12 empty-cart-cls text-center">
					// 														<img src={`${globalData.baseUrl}front/images/empty-cart.svg`} className="img-fluid" />
					// 														<h1 className="mb-3 theme-font-color"><strong className="font-weight-bold">Your shopping basket is empty</strong></h1>
					// 														{/* <h4>Add something to make us happy :)</h4> */}
					// 														{/* <Link to="#" className="btn cart-btn-transform m-3" data-abc="true">continue shopping</Link> */}
					// 													</div>
					// 												</div>
					// 											</div>
					// 										</div>
					// 									</div>
					// 								</div>
					// 							</div>
					// 						</div>
					// 					</div>
					// 				</div>
					// 			</div>
					// 		</section>
					// 	</div>
					// </div>
				}
				</>
			)
		}else{
			return (
				<div></div>
			)	
		}
}
}

const mapStateToProps = state => {
	return {
	  cartCount: state.cart_count,
	  userData: state.user_data
	}
  }
  
  const mapDispatchToProps = dispatch => {
	return {
		updateCartCount: ( (count) => dispatch({type: 'CART_COUNT', value: count})),
	}
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(Cart)

