import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import globalData from "../config.json";

function ForgotPassword(props) {
  window.scrollTo(0, 0);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [errorKeys, setErrorKeys] = useState([]);
  const [error, setError] = useState([]);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };
  useEffect(() => {
    const script = document.createElement("script");

    script.src = globalData.apiUrl + "/front/js/app-html.js";
    script.async = true;

    document.body.appendChild(script);
    script.onload = function () {
      // THEME.header.stickyHeaderInit(".hdr-content-sticky");
    };
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(globalData.apiUrl+"/api/forgot-password", {
        email: email,
      })
      .then((result) => {
        if (result.data.code == 200) {
          Swal.fire(
            "Thank You!",
            "Check your email we send mail for Reset Password!",
            "success"
          );
        }
      })
      .catch((err) => {
        // console.log(err.response.data);
        setErrorKeys(Object.keys(err.response.data));
        setError(err.response.data);
        setLoading(false);
        // console.log(error);
        return false;
      });
  }

  function handleChange(e) {
    if (e.target.name == "email") setEmail(e.target.value);
  }
  if(localStorage.getItem("token") == null && show)
  {
    return (
  
      <>
        <div
          id="main-wrapper"
          className="tour-login bg-dark for-padding"
          style={{ height: "100vh" }}
        >
          <div className="container">
            <div className="row g-0  py-4 py-md-5">
              <div className="col-lg-7 shadow-lg px-0">
                <div className="hero-wrap d-flex align-items-center rounded-3 rounded-end-0 h-100">
                  <div className="hero-mask opacity-9 bg-primary"></div>

                  <div
                    className="hero-bg hero-bg-scroll"
                  
                  ></div>

                  <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                    <div className="row g-0 my-auto d-flex justify-content-center">
                      <div className="col-6 col-lg-10 p-0">
                        <h1 className="text-11 text-white mb-1">
                          Forgot Password!
                        </h1>

                        <p className="text-5 text-white lh-base mb-md-4 mb-0">
                          Get access to your Orders, Wishlist and Recommendations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 shadow-lg align-items-center rounded-3 rounded-start-0 bg-dark justify-content-center">
                <div className="px-5 py-5">
                  <div className="px-4">
                    <h3 className="text-white text-center mb-4">
                      Forgot Password
                    </h3>
                    <form method="POST" onSubmit={handleSubmit}>
                      {error &&
                        errorKeys &&
                        errorKeys.map((key) => (
                          <div className="form-alert">
                            <Alert key={key} variant="danger">
                              <i className="fa fa-exclamation-triangle"></i>
                              {error[key]}
                            </Alert>
                          </div>
                        ))}

                      <div className="form-group">
                        <label htmlFor="email" className="form-label text-light">
                          Email Address
                        </label>

                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          onChange={handleChange}
                          name="email"
                          placeholder="Your Email"
                          required="required"
                        />
                      </div>

                      <div className="form-group">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="text-center mt-2">
                    <p className="text-light">
                      Have an account ?
                      <Link
                        href="login.html"
                        to={"/login"}
                        className="switcher-text2 inline-text ml-1"
                      >
                        Log in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }else{
    // return(<Link to='/'  />);
    return(
      <div className="page-content">
    
        <div id="my-account" className="bg-dark for-padding-review my-5">
          <div className="container">
            <div className="row text-center my-5">
              <div className="col-lg my-5">
                <h1 className="text-center text-white my-3">Coming Soon</h1>
              </div>
            </div>
          </div>
        </div>
        </div>
          
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (user) => dispatch({ type: "USER", value: user }),
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
