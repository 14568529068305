import React, { useState  } from "react";
import { Form, Card, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import validator from "validator";
import axios from 'axios';
import Cookies from "universal-cookie";
import globalData from "../config.json";
import Swal from "sweetalert2";
import $ from 'jquery';
import Loading from "../pages/Loading";
import { connect } from "react-redux";


const Final = ({ ipaddress, handleInputData, isInstallment, values, prevStep, firstStep, paymentHiddenRef }) => {

  window.scrollTo(0, 0);
  const [error, setError] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const submitFormData = (e) => {

    
    e.preventDefault();
    // console.log(values, "******************************");
    // checking if value of first name and last name is empty show error else take to next step
    if (!values.agree) {
      setError(true);
    } else {
      orderCreate(e);
    }
  };
  const cookies = new Cookies();


  // console.log(values, "***********************************************");

  const orderCreate = (e) => {

    setLoader(true);
    let is_guest = 0;
    let userObject = {
      "Browser CodeName": window.navigator.appCodeName,
      "Browser Name": window.navigator.appName,
      "Browser Version": window.navigator.appVersion,
      "Cookies Enabled": window.navigator.cookieEnabled,
      "Browser Language": window.navigator.language,
      "Browser Online": window.navigator.onLine,
      Platform: window.navigator.platform,
      "User-agent header": window.navigator.userAgent,
    };

    // console.log(JSON.stringify(window.navigator));
    e.preventDefault();

    // console.log(values.is_guest);
    if (values.is_guest == true) {
      is_guest = 1;
    }else if(values.is_guest == false) {
      is_guest = 0;
    }
    let token = "";
    let guesttoken = cookies.get("guesttoken");
    let postData = "";
    //  console.log(is_guest, "****************************");
    // return false;
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
     
      postData = {
        title: values.title,
        first_name: values.firstName,
        last_name: values.lastName,
        telephone: values.phone,
        email: values.email,
        line1: values.line1,
        line2: values.line2,
        line3: values.line3,
        town: values.city,
        county: values.County,
        postcode: values.zipcode,
        country: values.Country,
        paramId: guesttoken,
        ipAddress: ipaddress,
        userAgent: JSON.stringify(userObject),
      };
  } else if(is_guest == 1) {

      postData = {
        title: values.title,
        first_name: values.firstName,
        last_name: values.lastName,
        telephone: values.phone,
        email: values.email,
        line1: values.line1,
        line2: values.line2,
        line3: values.line3,
        town: values.city,
        county: values.County,
        postcode: values.zipcode,
        country: values.Country,
        isGuestCheckout: is_guest,
        paramId: guesttoken,
        ipAddress: ipaddress,
        userAgent: JSON.stringify(userObject),
      };
    }else{
      postData = {
        title: values.title,
        first_name: values.firstName,
        last_name: values.lastName,
        telephone: values.phone,
        email: values.email,
        c_email: values.c_email,
        password:values.password,
        c_password:values.c_password,
        line1: values.line1,
        line2: values.line2,
        line3: values.line3,
        town: values.city,
        county: values.County,
        postcode: values.zipcode,
        country: values.Country,
        isGuestCheckout: is_guest,
        paramId: guesttoken,
        ipAddress: ipaddress,
        userAgent: JSON.stringify(userObject),
      }
    }
    
  //  console.log(postData, "postData");
    // return false;
    axios({
        method: 'POST',
      url: globalData.apiUrl + "/api/order",
        headers: {
          'Cache-Control': 'no-cache',
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
          data: {
              ...postData
          }
       
        })
      .then((result) => {
        // alert('in payment step');
        // window.open(result.data.data.barclaysRedirectUrl, '_self', 'noopener,noreferrer');
        // console.log(typeof result.data.data.barclaysRedirectUrl == "undefined","******************************");
       if(result.data.length == undefined && typeof result.data.data.barclaysRedirectUrl == "undefined" ){
        Swal.fire({
          title:result.data.message,
          showConfirmButton: false,
          icon:"error",
          timer: 3000,
          timerProgressBar: true,
        });
        firstStep();
      }
        if(localStorage.getItem("token") &&  result.data.data !== undefined ){
            window.open(result.data.data.barclaysRedirectUrl, '_self', 'noopener,noreferrer')
        } else if( result.data.data !== undefined ) {
          if (/Mobi|Android/i.test(navigator.userAgent)) {
            // alert('mobile screen'+ result.data.data.barclaysRedirectUrl);

            setPaymentUrl(result.data.data.barclaysRedirectUrl);
            // $('#PaymentHidden').attr('href', result.data.data.barclaysRedirectUrl);
            // $('#PaymentHidden').trigger('click');
            // alert('url replace done');
            setShowModal(true);
            // window.location.href = result.data.data.barclaysRedirectUrl;
            // window.open(result.data.data.barclaysRedirectUrl, '_blank', 'noopener,noreferrer');
            // Your jQuery code for mobile devices
          } else {
            setLoader(false);
            window.open(result.data.data.barclaysRedirectUrl, '_self', 'noopener,noreferrer');
          }
           
        }else{
          Swal.fire({
            title:result.data.message,
            showConfirmButton: false,
            icon:"warning",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((error) => {
        // alert('in payment step');
     
        // console.log(error);
      });
  };

  return (
   
    <fieldset>
         {loader ? <Loading /> : ""}
      <div className="form-card">
        <div className="row">
          <div className="col-12">
            <h2 className="fs-title padding-20px-mobile">
              Payment Methods Accepted
            </h2>{" "}
          </div>
        </div>
        <div className="row padding-20px-mobile">
          <div className="col-md-1 col-sm-2 col-xs-1">
            <a href="#">
              {" "}
              <img src="../assets/images/cards/1.png" />{" "}
            </a>
          </div>
          <div className="col-md-1 col-sm-2 col-xs-1">
            <a href="#">
              {" "}
              <img src="../assets/images/cards/2.png" />{" "}
            </a>
          </div>
          <div className="col-md-1 col-sm-2 col-xs-1">
            <a href="#">
              {" "}
              <img src="../assets/images/cards/3.png" />{" "}
            </a>
          </div>
          <div className="col-md-1 col-sm-2 col-xs-1">
            <a href="#">
              {" "}
              <img src="../assets/images/cards/10.png" />{" "}
            </a>
          </div>
          <div className="col-md-1 col-sm-2 col-xs-1">
            <a href="#">
              {" "}
              <img src="../assets/images/cards/22.png" />{" "}
            </a>
          </div>
          { !isInstallment ?
          <>
          <div className="col-md-1 col-sm-2 col-xs-1">
            <a href="#">
              {" "}
              <img src="../assets/images/cards/16.png" />{" "}
            </a>
          </div>
        
          <div className="col-md-1 col-sm-2 col-xs-1">
            <a href="#">
              {" "}
              <img src="../assets/images/cards/14.png" />{" "}
            </a>
          </div>
          {/* <div className="col-md-2 col-sm-2 col-xs-1">
            <a href="#">
              {" "}
              <img src="https://twynhamstours.co.uk/public/images/cards/masterpass.png" />{" "}
            </a>
          </div> */}
          </>
          : ''
        }
        </div>
        <div className="row padding-20px-mobile">
          <div className="col-md-12">
            {/*  <h5 class="my-3">Please click here to confirm you have read and agree to our Terms and conditions</h5> */}
            <div className="form-check p-0 my-3">
              <input
                type="checkbox"
                className="form-check-input"
                name="agree"
                defaultChecked={values.agree}
                onChange={handleInputData("agree")}
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                <strong>
                  Please click here to confirm you have read and agree to our{" "}
                  <Link
                    to={"/terms-and-conditions"}
                    target="_blank"
                    className="link-color"
                  >
                    Terms and conditions
                  </Link>
                </strong>
              </label>
              {error ? (
                <Form.Text style={{ color: "red" }}>
                Please accept the Terms and Conditions
                </Form.Text>
              ) : (
                ""
              )}
            </div>
            <p className="my-3">
            When you click on the 'Place Order' button you will be redirected to our secure Barclays EPDQ Payment system. Should you have any questions or issues completing the payment transaction please call us on 0800 5999 077    ( +44 800 5999 077 ) and we will take your payment over the telephone.
            </p>
            {/* <button className="btn bg-dark mb-md-5">Buy Tickets</button> */}
          </div>
        </div>
      </div>
      <input
        type="button"
        name="next"
        className="next btn bta-block action-button"
        onClick={(e) => submitFormData(e)}
        defaultValue="PLACE ORDER"
      />
      {/* <a href="https://www.google.com/" class="btn btn-primary">google</a> */}
                
      <Modal show={showModal}
      onHide={() => setShowModal(false)} centered>
        <Modal.Header className="bg-white">
          {/* <Modal.Title>Custom Modal</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="bg-white">
          <h4>Please choose an action:</h4>

          <p>Click 'Ok' to proceed to the payment portal for checkout. </p>
          <p>Click 'Cancel' to discard the operation.</p>
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <Button className="btn home-button" variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Link className="btn btn-primary" to={paymentUrl} id="PaymentHiddenLink" target="_blank" rel="noopener noreferrer" ref={paymentHiddenRef}>
          Go to Payment Portal
          </Link>
        </Modal.Footer>
      </Modal>

      {/* <Link to={paymentUrl} id="PaymentHiddenLink" target="_blank" rel="noopener noreferrer" ref={paymentHiddenRef}>
        Go to Barclays
      </Link> */}
      <input
        type="button"
        name="previous"
        className="previous btn bta-block action-button-previous"
        onClick={prevStep}
        defaultValue="PREVIOUS"
      />
    </fieldset>

  );
};

const mapStateToProps = (state) => {
  return {
    showLogin: state.show_login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (user) => dispatch({ type: "USER", value: user }),
    hideLogin: () => dispatch({ type: "LOGIN_CONTROL", value: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Final);
