import React, { useState, useEffect } from "react";
import { Form} from "react-bootstrap";
// import validator from "validator";
// import Login from "../authuser/Login";
// import LoginCheckout from "../includes/LoginCheckout";

// creating functional component ans getting props from app.js and destucturing them
const StepTwo = (props) => {
  //creating error state for validation
  const [error, setError] = useState([]);
  const [checkLogin, setcheckLogin] = useState(true);
  const [login, setLogin] = useState(false);
  // console.log(props);


  useEffect(() => {
    // console.log(props);
    
     window.scroll(0,0)
    // console.log(checkLogin, 'checklogin true of false check');
    // console.log(props);
  }, []);
  useEffect(() => {
    // console.log(props);
    if (localStorage.getItem("token")) {
      setcheckLogin(false);
    }
    // window.scroll(0,0)
    // console.log(checkLogin, 'checklogin true of false check');
    // console.log(props);
  }, [props]);
  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    // console.log(validateForm(e));
    // checking if value of first name and last name is empty show error else take to next step
    if (validateForm(e)) {
      props.nextStep();
    }
  };

  const handleClick = (e) => {
   
      if(login)
      {
        setLogin(false);
      }else{
        setLogin(true);
      }

  };
  const validateForm = (e) => {
    let formIsValid = true;
    let Formerrors = [];

    if (!props.values.title) {
      formIsValid = false;
      Formerrors["title"] = "*Please enter your title.";
    }

    if (!props.values.firstName) {
      formIsValid = false;
      Formerrors["first_name"] = "*Please enter your First Name.";
    }

    if (typeof props.values.firstName !== "undefined") {
      if (!props.values.firstName.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        Formerrors["first_name"] = "*Please enter alphabet characters only.";
      }
    }

    if (!props.values.lastName) {
      formIsValid = false;
      Formerrors["lastName"] = "*Please enter your Last Name.";
    }

    if (typeof props.values.lastName !== "undefined") {
      if (!props.values.lastName.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        Formerrors["lastName"] = "*Please enter alphabet characters only.";
      }
    }

    if (!props.values.email) {
      formIsValid = false;
      Formerrors["email"] = "*Please enter your email.";
    }

    if (typeof props.values.email !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(props.values.email)) {
        formIsValid = false;
        Formerrors["email"] = "*Please enter valid email.";
      }
    }
    if ( props.values.is_guest === false) {
      if (!props.values.c_email) {
        formIsValid = false;
        Formerrors["c_email"] = "*Please enter your confirm email.";
      }
    }

    if (typeof props.values.c_email !== "undefined" && props.values.c_email && checkLogin == true &&  props.values.is_guest === false) {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(props.values.c_email)) {
        formIsValid = false;
        Formerrors["c_email"] = "*Please enter valid confirm email.";
      } else if (props.values.c_email != props.values.email) {
        formIsValid = false;
        Formerrors["c_email"] = "*Please enter same as email.";
      }
      if (!props.values.password) {
        formIsValid = false;
        Formerrors["password"] = "*Please enter your password.";
      }
  
      if (!props.values.c_password) {
        formIsValid = false;
        Formerrors["c_password"] = "*Please enter your confirm password.";
      }
  
      if (props.values.c_password != props.values.password) {
        formIsValid = false;
        Formerrors["c_password"] = "*Please enter same as password.";
      }
    }

   

    if (!props.values.phone) {
      formIsValid = false;
      Formerrors["phone"] = "*Please enter your phone.";
    }

    if (!props.values.line1) {
      formIsValid = false;
      Formerrors["line1"] = "*Please enter your address.";
    }

    if (!props.values.city) {
      formIsValid = false;
      Formerrors["city"] = "*Please enter your city.";
    }

    if (!props.values.County) {
      formIsValid = false;
      Formerrors["county"] = "*Please enter your county.";
    }

    if (!props.values.zipcode) {
      formIsValid = false;
      Formerrors["zipcode"] = "*Please enter your zipcode.";
    }

    if (!props.values.Country) {
      formIsValid = false;
      Formerrors["country"] = "*Please enter your country.";
    }
    // console.log(Formerrors);
    setError([...error, Formerrors]);

    return formIsValid;
  };

  return (
    <>
      <fieldset>
    
        { checkLogin === true ?
        <>
          <div className="row">
            {/* <div className="col-md-7">
              <div className="my-2 fs-title checkout-mobile-res"> If you would like to checkout as a guest,
                <label className="text-dark fs-title" style={{ cursor: "pointer"}} htmlFor="exampleCheck1" name="is_guest_click" > Click here</label>
                <input type="checkbox" className="form-check-input" name="is_guest" checked={props.values.is_guest ? true : false }  onChange={props.handleInputData("is_guest")} id="exampleCheck1" />
                  <label className="fieldlabels ml-3 my-20" htmlFor="exampleCheck1" >
                  <strong></strong>
                </label>
              </div>
            </div>
          </div>
        <div className="row"> */}
            {/* <div className="col-md-5"> */}
            {/* <input type="checkbox" className="form-check-input" defaultChecked={props.values.is_guest}  onChange={props.handleInputData("is_guest")} id="exampleCheck1" />
              <label className="fieldlabels ml-3 my-3" htmlFor="exampleCheck1" >
                <strong>Checkout As Guest</strong>
              </label> */}
            {/* </div> */}
            {/* <div className="col-md-5">
              <div className="my-3 fs-title checkout-mobile-res">Returning customer?
                <a className="text-dark" style={{ cursor: "pointer"}} onClick={(e) => handleClick(e)}> Click here to login</a>
              </div>
            </div>
          { login ?
            <LoginCheckout  /> 
            : ''
           } */}
        </div>
        </>
        : ''
        }
        <div className="form-card">
          <div className="row">
            <div className="col-md-6 col-sm-12 for-mobile-size-padding mb-3">
              <h2 className="fs-title">Billing Details</h2>
              <label className="fieldlabels">
                Title <span className="required text-danger">*</span>
              </label>
              { checkLogin ?
              <select
                name="title"
                onChange={props.handleInputData("title")}
                value={props.values.title}
              >
                <option value="0">Select Your Title</option>
                <option>Mr</option>
                <option>Mrs</option>
                <option>Miss</option>
                <option>Ms</option>
                <option>Dr</option>
              </select>
              : 
              <select
                name="title"
                
                value={props.values.title} disabled
              >
                <option value="0">Select Your Title</option>
                <option>Mr</option>
                <option>Mrs</option>
                <option>Miss</option>
                <option>Ms</option>
                <option>Dr</option>
              </select>
              }
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>{error[0].title}</Form.Text>
              ) : (
                ""
              )}
              <label className="fieldlabels">
                First Name <span className="required text-danger">*</span>
              </label>
              { checkLogin ?
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                onChange={props.handleInputData("firstName")}
                value={props.values.firstName}
                /> 
                : <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="for-email-cursor"
                value={props.values.firstName} disabled
              /> 
              }
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>
                  {error[0].first_name}
                </Form.Text>
              ) : (
                ""
              )}
              {/* {  this.state.errors.first_name && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ this.state.errors.first_name }</strong></div>} */}
              <label className="fieldlabels">
                Last Name <span className="required text-danger">*</span>
              </label>
              { checkLogin ?
              <input
                type="text"
                name="lastName"
                
                placeholder="Last Name"
                onChange={props.handleInputData("lastName")}
                value={props.values.lastName}
              />
              :
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="for-email-cursor"
                value={props.values.lastName} disabled
              />
            }
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>
                  {error[0].lastName}
                </Form.Text>
              ) : (
                ""
              )}
              
              <label className="fieldlabels">
                Telephone <span className="required text-danger">*</span>
              </label>
              { checkLogin ?
              <input
                type="text"
                name="phone"
                placeholder="Telephone"
                onChange={props.handleInputData("phone")}
                value={props.values.phone}
              />
              : <input
              type="text"
              name="phone"
              placeholder="Telephone"
              className="for-email-cursor"
              value={props.values.phone} disabled
            />
              }
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>{error[0].phone}</Form.Text>
              ) : (
                ""
              )}
              {/* {  this.state.errors.lastName && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ this.state.errors.lastName }</strong></div>} */}
              <label className="fieldlabels">
                Email <span className="required text-danger">*</span>
              </label>
              { checkLogin ?
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={props.handleInputData("email")}
                value={props.values.email}
              />
              :
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="for-email-cursor"
                value={props.values.email} disabled
              />
            }
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>{error[0].email}</Form.Text>
              ) : (
                ""
              )}
              {/* {  this.state.errors.email && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ this.state.errors.email }</strong></div>} */}
              { checkLogin && props.values.is_guest == '' ?
              <>
              <label className="fieldlabels">
                Confirm Email <span className="required text-danger">*</span>
              </label>
              <input
                type="email"
                name="c_email"
                placeholder="Confirm Email"
                autoComplete="new-password" 
                onChange={props.handleInputData("c_email")}
                value={props.values.c_email}
              />
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>
                  {error[0].c_email}
                </Form.Text>
              ) : (
                ""
              )}

              <label className="fieldlabels">
                Password <span className="required text-danger">*</span>
              </label>
              <input
                type="password"
                name="password"
                placeholder="Enter Password"
                autoComplete="new-password" 
                onChange={props.handleInputData("password")}
                value={props.values.password}
              />
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>
                  {error[0].password}
                </Form.Text>
              ) : (
                ""
              )}

              <label className="fieldlabels">
                Confirm Password <span className="required text-danger">*</span>
              </label>
              <input
                type="password"
                name="c_password"
                placeholder="Confirm Password"
                autoComplete="new-password" 
                onChange={props.handleInputData("c_password")}
                value={props.values.c_password}
              />
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>
                  {error[0].c_password}
                </Form.Text>
              ) : (
                ""
              )}
              
              </>
              : ''
              }

              {/* {  this.state.errors.phone && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ this.state.errors.phone }</strong></div>} */}
            </div>
            <div className="col-md-6 col-sm-12 for-mobile-size-padding">
              <h2 className="fs-title">Address Details</h2>
              <label className="fieldlabels">
                Line 1 <span className="required text-danger">*</span>
              </label>
              <input
                type="text"
                name="line1"
                onChange={props.handleInputData("line1")}
                value={props.values.line1}
              />
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>{error[0].line1}</Form.Text>
              ) : (
                ""
              )}
              {/* {  this.state.errors.line1 && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ values.errors.line1 }</strong></div>} */}
              <label className="fieldlabels">Line 2</label>
              <input
                type="text"
                name="line2"
                onChange={props.handleInputData("line2")}
                value={props.values.line2}
              />

              {/* {  this.state.errors.line2 && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ this.state.errors.line2 }</strong></div>} */}
              <label className="fieldlabels">Line 3</label>
              <input
                type="text"
                name="line3"
                onChange={props.handleInputData("line3")}
                value={props.values.line3}
              />

              {/* {  this.state.errors.line3 && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ this.state.errors.line3 }</strong></div>} */}
              <label className="fieldlabels">
                Town / City <span className="required text-danger">*</span>
              </label>
              <input
                type="text"
                name="city"
                onChange={props.handleInputData("city")}
                value={props.values.city}
              />
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>{error[0].city}</Form.Text>
              ) : (
                ""
              )}
              {/* {  this.state.errors.city && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ this.state.errors.city }</strong></div>} */}
              <label className="fieldlabels">
                County <span className="required text-danger">*</span>
              </label>
              <input
                type="text"
                name="County"
                onChange={props.handleInputData("County")}
                value={props.values.County}
              />
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>
                  {error[0].county}
                </Form.Text>
              ) : (
                ""
              )}
              {/* {  this.state.errors.county && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ this.state.errors.county }</strong></div>} */}
              <label className="fieldlabels">
                Postcode / Zip code{" "}
                <span className="required text-danger">*</span>
              </label>
              <input
                type="text"
                name="zipcode"
                onChange={props.handleInputData("zipcode")}
                value={props.values.zipcode}
              />
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>
                  {error[0].zipcode}
                </Form.Text>
              ) : (
                ""
              )}
              {/* {  this.state.errors.zipcode && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ this.state.errors.zipcode }</strong></div>} */}
              <label className="fieldlabels">
                Country <span className="required text-danger">*</span>
              </label>
              <input
                type="text"
                name="Country"
                onChange={props.handleInputData("Country")}
                value={props.values.Country}
              />
              {error.length > 0 ? (
                <Form.Text style={{ color: "red" }}>
                  {error[0].country}
                </Form.Text>
              ) : (
                ""
              )}
              
              {/* {  this.state.errors.country && <div className="invalid-feedback" style={{ display: "block"}}><strong>{ this.state.errors.country }</strong></div>} */}
            </div>
          </div>
        </div>
        <input
          type="button"
          name="next"
          className="next btn bta-block action-button"
          onClick={(e) => submitFormData(e)}
          defaultValue="NEXT"
        />
        <input
          type="button"
          name="previous"
          className="previous btn bta-block action-button-previous"
          onClick={props.prevStep}
          defaultValue="PREVIOUS"
        />
      </fieldset>
    </>
  );
};

export default StepTwo;
