import React, { useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import globalData from "../config.json";
import Swal from "sweetalert2";

function ResetPassword(props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorKeys, setErrorKeys] = useState([]);
  const [successKeys, setSuccessKeys] = useState([]);
  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);
  const handleClose = () => setShow(false);

  const { id } = useParams();

  const handleShow = () => {
    setShow(true);
  };

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(globalData.apiUrl + "/api/reset-password", {
        password: password,
        password_confirmation: passwordConfirm,
        token: id,
      })
      .then((result) => {


        localStorage.setItem("token", result.data.token);

        // console.log(props);
        // return false;
        // setSuccessKeys(Object.keys(JSON.parse(result.response.data)))
        // setSuccess(JSON.parse(result.response.data))
        // props.addUser(result.data.user);
        Swal.fire("Thank You!", "Congratulation!! Your password changed successfully.", "success");
        window.location.href = globalData.redirectUrl + "login";
      })
      .catch((err) => {
       
        // setErrorKeys(err.response.data);
        // setError(err.response.data);

        setErrorKeys(Object.keys(err.response.data.error));
        setError(err.response.data.error);

        //  console.log( errorKeys, error, Object.keys(err.response.data), Object.values(err.response.data));
        setLoading(false);
      });
  }

  function handleChange(e) {
    if (e.target.name == "password") setPassword(e.target.value);
    if (e.target.name == "c_password") setPasswordConfirm(e.target.value);
  }

  if(localStorage.getItem("token") == '' || localStorage.getItem("token") == undefined ){
    // console.log(errorKeys, error) 
  return (  
    
   

    <>
      <div
        id="main-wrapper"
        className="tour-login bg-dark for-padding"
        style={{ height: "100vh" }}
      >
        <div className="container">
          <div className="row g-0  py-4 py-md-5">
            <div className="col-lg-6 shadow-lg px-0">
              <div className="hero-wrap d-flex align-items-center rounded-3 rounded-end-0 h-100">
                <div className="hero-mask opacity-9 bg-primary"></div>

                <div
                  className="hero-bg hero-bg-scroll"
                  style={{
                    backgroundImage:
                      "url('/twynhams-tours/public/front/images/figure/bg13-l.jpg')",
                  }}
                ></div>

                <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                  <div className="row g-0 my-auto d-flex justify-content-center">
                    <div className="col-6 col-lg-6 p-0">
                      <h1 className="text-11 text-white mb-1">
                        Reset Password!
                      </h1>

                      <p className="text-5 text-white lh-base mb-md-4 mb-0">
                        Get access to your Orders, Wishlist and Recommendations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 shadow-lg d-flex align-items-center rounded-3 rounded-start-0 bg-dark justify-content-center">
              <div className="px-0 py-5">
                <div className="p-0">
                  <h3 className="text-white text-center mb-4">
                    Reset Password
                  </h3>

                  <form method="POST" onSubmit={handleSubmit}>
                   
                  {error &&
                      errorKeys &&
                      errorKeys.map((key) => (
                        error[key] !== undefined ?
                          <div className="form-alert">
                            <Alert key={key} variant="danger">
                              <i className="fa fa-exclamation-triangle"></i>
                              {error[key]}
                            </Alert>
                          </div>
                          : ''
                      ))}
                  

                    <div className="form-group">
                      <label
                        htmlFor="password"
                        className="form-label text-light"
                      >
                        Password
                      </label>

                      <input
                        id="password"
                        type="password"
                        className="form-control"
                        onChange={handleChange}
                        name="password"
                        placeholder="********"
                        required="required"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="c_password"
                        className="form-label text-light"
                      >
                        Confirm Password
                      </label>

                      <input
                        id="c_password"
                        type="password"
                        className="form-control"
                        onChange={handleChange}
                        name="c_password"
                        placeholder="********"
                        required="required"
                      />
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>

                <div className="text-center mt-2">
                  <p className="text-light">
                    Have an account ?
                    <Link
                      href="login.html"
                      to={"/login"}
                      className="switcher-text2 inline-text ml-1"
                    >
                      Log in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  }else{
   
      window.location.href = globalData.redirectUrl;
    
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (user) => dispatch({ type: "USER", value: user }),
  };
};

export default connect(null, mapDispatchToProps)(ResetPassword);
