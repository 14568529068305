import React, { useState } from "react";
// import { Form, Card, Button } from "react-bootstrap";
// import validator from "validator";
import Cookies from "universal-cookie";
import ReactHtmlParser from "react-html-parser";
// import globalData from "../config.json";
// import Swal from "sweetalert2";

const cookies = new Cookies();

// creating functional component ans getting props from app.js and destucturing them
const StepOne = ({
  gotoprevious,
  nextStep,
  handleFormData,
  handleChange,
  removeCart,
  values,
  cartlist,
  total,
  subtotal,
  isInstallment,
  outofstockPackageQuantity,
}) => {
  //creating error state for validation
  const [error, setError] = useState(false);
  let cartList = '';
  if(cartlist[0] !== undefined ){
   cartList = cartlist[0];
}
  

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    // if(outofstockPackageQuantity){

    //   Swal.fire("One of your item in cart has been out of stock Please remove then Continue", "", "error");

    // }else{

      nextStep();
    // }
  };

  return (
    <div>
      <fieldset>
        <div className="form-card">
          <div id="checkout-page">
            <section className="shopping-cart ">
              <div className="container">
                <div className="block-heading"> </div>
                <div className="content">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="items">
                       
                        {cartlist
                          ? cartlist.map((ct, index) => {
                              return (
                                <div className="product" key={ct.id}>
                                  <div className="row">
                                    {/* <div className="col-md-1">
                                      <img
                                        className="img-fluid mx-auto d-block image"
                                        src={ct.eventImageThumbnail}
                                      />
                                    </div> */}
                                    <div className="col-md-12">
                                      <div className="info">
                                        <div className="row d-flex align-items-center">
                                          <div className="col-md-5 product-name">
                                            <div className="product-name">
                                              {" "}
                                              <a href="#">
                                                Package Description
                                              </a>
                                              <div className="product-info">
                                                <div className="price">{ct.packageName}</div>
                                                {ct.package_status  ?
                                                <p className="text-danger">{ct.package_status}</p>
                                                : ''
                                                }
                                              </div>
                                            </div>
                                            {ct.meta.is_installments == 1 ? (
                                              <div className="product-name">
                                                {" "}
                                                <a href="#">Instalment</a>
                                                <div className="product-info">
                                                  <div>
                                                    {ReactHtmlParser(
                                                      ct.installmentsData
                                                        .installmentsMessage
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div className="col-md-2 quantity ">
                                            <label htmlFor="quantity">
                                              Quantity
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control select-width"
                                              name="quantity"
                                              id="quantity"
                                              onChange={(e) =>
                                                handleChange(ct.id, e)
                                              }
                                              defaultValue={ct.quantity}
                                             
                                            />
                                          </div>
                                          <div className="col-md-2">
                                            <div className="product-name">
                                              {" "}
                                              <a href="#">Price</a>
                                              <div className="product-info">
                                                <div className="price">
                                                  £{ct.unit_price}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-2">
                                            <div className="product-name">
                                              {" "}
                                              <a href="#">Subtotal</a>
                                              <div className="product-info">
                                                <div className="price">
                                                  £{ct.total_price}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-1 d-flex justify-content-center align-items-center">
                                            <div className="product-name">
                                              <div className="product-info">
                                                <div className="price">
                                                  <a
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) =>
                                                      removeCart(
                                                        ct.idEncrypt,
                                                        e
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="icon-recycle"
                                                      style={{ fontSize: 20 }}
                                                    />
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    {/* <div className="col-md-1"></div> */}
                                    <div className="col-md-12">
                                      <div className="info">
                                        <div className="row">
                                          <div className="col-md-5 product-name">
                                            {ct.supplements_status == 1 &&ct.supplements_total_number > 0 ? (
                                              <div className="product-name">
                                                {" "}
                                                <a href="#">Supplements</a>
                                                <div className="product-info">
                                                  <div>
                                                    {
                                                      ct.cartItemSupplement
                                                        .supplementsData
                                                        .supplements_p1
                                                    }
                                                    &nbsp;&nbsp;{" "}
                                                    {
                                                      ct.cartItemSupplement
                                                        .supplementsData
                                                        .supplements_p2
                                                    }
                                                    &nbsp;
                                                    {
                                                      ct.cartItemSupplement
                                                        .supplementsData
                                                        .supplements_p3
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {ct.supplements_status == 1 && ct.supplements_total_number > 0 ? 
                                              <div className="product-name">
                                                 { ct.cartItemSupplement.supplementsData.installmentsData !== undefined ? (
                                                <>
                                                <a href="#">Instalment</a>
                                                <div className="product-info">
                                                  <div>
                                                    {ReactHtmlParser(
                                                      ct.cartItemSupplement
                                                        .supplementsData
                                                        .installmentsData
                                                        .installmentsMessage
                                                    )}
                                                  </div>
                                                </div>
                                                </>
                                            ) : (
                                              ""
                                            )}
                                            </div>
                                            : ""
                                            }
                                          </div>
                                          <div className="col-md-2 quantity ">
                                            {ct.supplements_status == 1 && ct.supplements_total_number > 0 ? (
                                              <>
                                                <label
                                                  htmlFor="quantity"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  Supplement
                                                </label>
                                               
                                                <select
                                                  defaultValue={
                                                    ct.cartItemSupplement
                                                      .supplementsData
                                                      .selectedSupplement
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      ct.cartItemSupplement.id,
                                                      e
                                                    )
                                                  }
                                                  className="form-control select-width"
                                                >
                                                  
                                                  {ct.cartItemSupplement.supplementsData.noOfSingleRoomSupplements.map(
                                                    (item) =>
                                                      ct.cartItemSupplement
                                                        .supplementsData
                                                        .selectedSupplement ==
                                                      item.optionValue ? (
                                                        <option
                                                          value={
                                                            item.optionValue
                                                          }
                                                          key={item.optionValue}
                                                          selected
                                                        >
                                                          {item.optionName}
                                                        </option>
                                                      ) : (
                                                        <option
                                                          value={
                                                            item.optionValue
                                                          }
                                                          key={item.optionValue}
                                                        >
                                                          {item.optionName}
                                                        </option>
                                                      )
                                                  )}
                                                </select>
                                                
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div className="col-md-2">
                                            {ct.supplements_status == 1 && ct.supplements_total_number > 0 ? (
                                              <div className="product-name mt-4">
                                                {/* <a href="#">Price</a> */}
                                                <div className="product-info">
                                                  <div className="price">
                                                    £
                                                    {
                                                      ct.cartItemSupplement
                                                        .unit_price
                                                    }{" "}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              " "
                                            )}
                                          </div>
                                          <div className="col-md-2">
                                            {ct.supplements_status == 1 && ct.supplements_total_number > 0 ? (
                                              <div className="product-name mt-4">
                                                {/* <a href="#">Subtotal</a> */}
                                                <div className="product-info">
                                                  <div className="price">
                                                    £
                                                    {
                                                      ct.cartItemSupplement
                                                        .total_price
                                                    }{" "}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div className="col-md-1 d-flex justify-content-center align-items-center"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-12">
                      <div className="summary">
                        { cartList && isInstallment == "1" ?
                          <div className="summary-item">
                            <span className="text">TOTAL TOUR PRICE</span>
                            <span className="price">£{subtotal}</span>
                          </div>
                          : ''
                        }
                        <div className="summary-item">
                        { cartList !== "undefined" ? 
                          <span className="text">
                            
                            { cartList && cartList.meta.is_installments == "1" ?
                              "First Instalment Price"
                              : 'Total Price'
                            }

                          </span>
                        
												 : ''
												}
                        
                          <span className="price">£{total}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row text-center">
                      <div className="col-md-12">
                        <p className="mt-md-5 mb-md-2">
                          <strong>Please Note: </strong> 
                          For our European and multi-night tours,
                          any tickets that are not provided in ‘e-ticket’ format, are delivered personally 
                          by one of our team on your arrival, for security reasons.Should you have any questions or doubts regarding your 
                          order or your payment by instalments, please do not 
                          hesitate to contact our customer service team by email at 
                          <a href="#" className="link-color">
                            {" "}
                            sales@twynhamstours.co.uk
                          </a>{" "}
                          or by phone on 07739 944 339 (+44 7739 944 339) for
                          further information.
                          </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <input
          type="button"
          name="previous"
          className="previous btn btn-block action-button-previous"
          onClick={(e) => gotoprevious(e)}
          defaultValue="CONTINUE SHOPPING"
        />
        <input
          type="button"
          name="next"
          className="next btn btn-block action-button"
          onClick={submitFormData}
          defaultValue="CHECKOUT"
        />
      </fieldset>
    </div>
  );
};

export default StepOne;
