import React, { Component } from "react";

class Loading extends Component {
 
  render() {
    return (
        <div className="overlay">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spinner" /></div>
            </div>
        </div>
    );
  }
}

export default Loading;


