import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import globalData from "../config.json";
import { connect } from "react-redux";

function Register(props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [errorKeys, setErrorKeys] = useState([]);
  const [error, setError] = useState([]);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (this.validateForm()) {
      axios
        .post(globalData.apiUrl + "/api/register", {
          first_name: first_name,
          last_name: last_name,
          email: email,
          password: password,
          password_confirmation: passwordConfirm,
        })
        .then((result) => {
          localStorage.setItem("token", result.data.token);
          props.addUser(result.data.user);
          window.location.href = "/#/user-login";
        })
        .catch((err) => {
          setErrorKeys(Object.keys(JSON.parse(err.response.data)));
          setError(JSON.parse(err.response.data));
          setLoading(false);
        });
    }
  }

  function handleChange(e) {
    if (e.target.name == "f_name") setFirstname(e.target.value);
    if (e.target.name == "l_name") setLastname(e.target.value);
    if (e.target.name == "email") setEmail(e.target.value);
    if (e.target.name == "password") setPassword(e.target.value);
    if (e.target.name == "c_password") setPasswordConfirm(e.target.value);
  }

  function validateForm() {
    let errors = {};
    let formIsValid = true;

    if (!first_name) {
      formIsValid = false;
      errors["first_name"] = "*Please enter your first_name.";
    }

    if (typeof first_name !== "undefined") {
      if (!first_name.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["first_name"] = "*Please enter alphabet characters only.";
      }
    }

    if (!last_name) {
      formIsValid = false;
      errors["last_name"] = "*Please enter your last_name.";
    }

    if (typeof last_name !== "undefined") {
      if (!last_name.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["last_name"] = "*Please enter alphabet characters only.";
      }
    }

    if (!email) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof email !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }

    if (!password) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

    if (typeof password !== "undefined") {
      if (
        !password.match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }
    if (password != passwordConfirm) {
      formIsValid = false;
      errors["c_password"] = "*Password does't match.";
    }

    setError(errors);
    return formIsValid;
  }

  if(show)
  {
    return (
      <>
        <div id="main-wrapper" className="tour-login bg-dark for-padding">
          <div className="container">
            <div className="row g-0  py-4 py-md-5">
              <div className="col-lg-11 shadow-lg px-0">
                <div className="hero-wrap d-flex align-items-center rounded-3 rounded-end-0 h-100">
                  <div className="hero-mask opacity-9 bg-primary"></div>

                  <div
                    className="hero-bg hero-bg-scroll"
                    style={{
                      backgroundImage: "url('../assets/images/figure/bg13-l.jpg')",
                    }}
                  ></div>

                  <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                    <div className="row g-0 my-auto d-flex justify-content-center">
                      <div className="col-12 col-lg-12 p-0">
                        <h1 className="text-11 text-white mb-1">Welcome back!</h1>

                        <p className="text-5 text-white lh-base mb-md-4 mb-0">
                          Get access to your Orders, Wishlist and Recommendations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 shadow-lg d-flex align-items-center rounded-3 rounded-start-0 bg-dark justify-content-center">
                <div className="  px-0 py-5">
                  <div className=" p-0">
                    <h3 className="text-white text-center mb-4">Sign In</h3>
                    <form method="POST" onSubmit={handleSubmit}>
                      {error &&
                        errorKeys &&
                        errorKeys.map((key) => (
                          <div className="form-alert">
                            <Alert key={key} variant="danger">
                              <i className="fa fa-exclamation-triangle"></i>
                              {error[key]}
                            </Alert>
                          </div>
                        ))}

                      <div className="form-group">
                        <label htmlFor="f_name" className="input-label">
                          First Name
                        </label>

                        <input
                          type="text"
                          id="f_name"
                          className="form-control"
                          onChange={handleChange}
                          name="f_name"
                          placeholder="Your First Name"
                          required="required"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="l_name" className="input-label">
                          Last Name
                        </label>

                        <input
                          type="text"
                          id="l_name"
                          className="form-control"
                          name="l_name"
                          onChange={handleChange}
                          placeholder="Your Last Name"
                          required="required"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="email" className="input-label">
                          Email Address
                        </label>

                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          onChange={handleChange}
                          name="email"
                          placeholder="Your Email"
                          required="required"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="password" className="input-label">
                          Password
                        </label>

                        <input
                          id="password"
                          type="password"
                          className="form-control"
                          onChange={handleChange}
                          name="password"
                          placeholder="********"
                          required="required"
                        />

                        <i
                          toggle="#password"
                          className="fa fa-fw fa-eye toggle-password field-icon"
                        ></i>
                      </div>

                      <div className="form-group">
                        <label htmlFor="c_password" className="input-label">
                          Confirm Password
                        </label>

                        <input
                          id="c_password"
                          type="password"
                          className="form-control"
                          onChange={handleChange}
                          name="c_password"
                          placeholder="********"
                          required="required"
                        />

                        <i
                          toggle="#c_password"
                          className="fa fa-fw fa-eye toggle-password field-icon"
                        ></i>
                      </div>

                      <div className="form-group">
                        <div className="fxt-checkbox-area">
                          <div className="checkbox">
                            <input id="checkbox1" type="checkbox" />

                            <label htmlFor="checkbox1">
                              I agree with the terms and condition
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <button type="submit" className="fxt-btn-fill">
                          Register
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }else{
    return(
      <div className="page-content">
    
        <div id="my-account" className="bg-dark for-padding-review my-5">
          <div className="container">
            <div className="row text-center my-5">
              <div className="col-lg my-5">
                <h1 className="text-center text-white my-3">Coming Soon</h1>
              </div>
            </div>
          </div>
        </div>
        </div>
          
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (user) => dispatch({ type: "USER", value: user }),
  };
};

export default connect(null, mapDispatchToProps)(Register);
