import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import globalData from "../config.json";

class Notfound extends Component {
  constructor(props) {
    super(props);
  }


  loadScript() {
    let scripts = [{ src: globalData.apiUrl + "/front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });
  }
  componentDidMount() {
    // window.scrollTo(0, 0);
    this.loadScript();
  }

  componentDidUpdate() {}

  render() {
    return (
      <div className="page-404">
        <div className="outer">
          <div className="middle">
            <div className="inner">
              <div className="inner-circle">
                <i className="fa fa-home" />
                <span>404</span>
              </div>
              <span className="inner-status">Oops! You're lost</span>
              <span className="inner-detail">
                We can not find the page you're looking for.
              </span>
              <Link to={"/"} className="btn bg-cus mtl d-block">
                <i className="fa fa-home" />
                &nbsp; Return home
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartCount: state.cart_count,
    userData: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notfound);
