import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import globalData from "../config.json";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
function Login(props) {
  window.scrollTo(0, 0);
  
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailerror, setemailError] = useState("");
  const [password, setPassword] = useState("");
  const [passworderror, setpasswordError] = useState("");
  const [error, setError] = useState("");
  const recaptchaRef = React.createRef();


  useEffect(() => {
    loadScript();
  }, []);

  const loadScript = () => {
    let scripts = [{ src: globalData.appUrl + "front/js/app-html.js" }];

    //Append the script element on each iteration
    // console.log(" Contact Loadscript)", scripts);
    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });

    const script = document.getElementById("myScript");
    if (script === undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });

    }
    
  }

  const handleClose = () => {
    setShow(false);
    props.hideLogin();
  };

  const handleShow = () => {
    setShow(true);
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!email) {
      formIsValid = false;
      setemailError("Please enter your email-ID.");
    }

    if (!password) {
      formIsValid = false;
      setpasswordError("Please enter your password.");
    }

    return formIsValid;
  };
  function handleSubmit(e) {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();

    if (recaptchaValue != "") {
      if (validateForm()) {
        setLoading(true);
        axios
          .post(globalData.apiUrl + "/api/login", {
            email: email,
            password: password,
            paramId: cookies.get('guesttoken'),
          })
          .then((result) => {
            // console.log(props);
            localStorage.setItem("token", result.data.token);
            props.addUser(result.data.user);
            if (localStorage.getItem("token")) {
              window.location.href = globalData.redirectUrl;
            }
          })
          .catch((error) => {
            setError("Invalid credentials!");
            setLoading(false);
          });
      } else {
        setError("");
        setLoading(false);
      }
    } else {
      setError("Please Verify ReCAPTCHA");
      setLoading(false);
    }
  }

  function handleChange(e) {
    if (e.target.name == "email") setEmail(e.target.value);
    if (e.target.name == "password") setPassword(e.target.value);
  }


  if(localStorage.getItem("token") == null && show)
  {
    return (
      <>
        <div id="main-wrapper" className="tour-login bg-dark for-padding">
          <div className="container">
            <div className="row g-0  pb-4 pb-md-5">
              <div className="col-lg-7 shadow-lg px-0">
                <div className="hero-wrap d-flex align-items-center rounded-3 rounded-end-0 h-100">
                  <div className="hero-mask opacity-9 bg-primary"></div>
                  <div
                    className="hero-bg hero-bg-scroll"
                    
                  ></div>
                  <div className="hero-content mx-auto w-100 d-flex flex-column h-100">
                    <div className="row g-0 my-auto d-flex justify-content-center">
                      <div className="col-6 col-lg-10 p-0">
                        <h1 className="text-11 text-white mb-1">Welcome Back!</h1>
                        <p className="text-5 text-white lh-base mb-md-4 mb-0">
                          Login here, for access to your orders and account
                          details.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 shadow-lg d-lg-block d-sm-flex d-xs-flex align-items-center rounded-3 rounded-start-0 bg-dark justify-content-center">
                <div className="px-lg-5 py-md-5 for-responsive-space">
                  <div className="px-lg-4">
                    <h3 className="text-white text-center mb-4">Account Login</h3>
                    <form
                      method="POST"
                      id="loginForm"
                      className="form-dark"
                      onSubmit={handleSubmit}
                    >
                      <div className="mb-2">
                        <label className="form-label text-light" htmlFor="email">
                          Email Address
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          onChange={handleChange}
                          placeholder="Enter Email"
                        />
                        {emailerror && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            <strong>{emailerror}</strong>
                          </div>
                        )}
                      </div>
                      <div className="mb-2">
                        <label
                          className="form-label text-light"
                          htmlFor="password"
                        >
                          Password
                        </label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          className="form-control"
                          onChange={handleChange}
                          placeholder="Enter Password"
                        />
                        {passworderror && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            <strong>{passworderror}</strong>
                          </div>
                        )}
                      </div>
                      <div className="row mt-2">
                        <div className="col">
                          {/* <div className="form-check text-2 p-0">
                            <input
                              id="remember-me"
                              name="remember"
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label text-light"
                              htmlFor="remember-me"
                            >
                              Remember Me
                            </label>
                          </div> */}
                        </div>
                        <div className="col-sm text-2 text-right">
                          <Link to={"/forgot-password"}>Forgot Password ?</Link>
                        </div>
                      </div>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LcQqaweAAAAALVypwG48-6xmuTXLuSB_ZHnoW2I"
                        onChange={handleChange}
                        onExpired={() => {
                          recaptchaRef.current.reset();
                        }}
                      />
                      {error && (
                        <div className="form-alert">
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            <strong>{error}</strong>
                          </div>
                        </div>
                      )}
                      <div className="d-grid my-2">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }else{
   
    return(
        <div className="page-content">
      
          <div id="my-account" className="bg-dark for-padding-review my-5">
            <div className="container">
              <div className="row text-center my-5">
                <div className="col-lg my-5">
                  <h1 className="text-center text-white my-3">Coming Soon</h1>
                </div>
              </div>
            </div>
          </div>
          </div>
            
      );
  }
}

const mapStateToProps = (state) => {
  return {
    showLogin: state.show_login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (user) => dispatch({ type: "USER", value: user }),
    hideLogin: () => dispatch({ type: "LOGIN_CONTROL", value: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
