import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import globalData from "../config.json";
import Loading from "./Loading";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';



function Event(props) {
  const location = useLocation();
  // console.log(location.state);
  const [Data, setData] = useState([]);
  const [loading, setloading] = useState(true);

  const [message, setMessage] = useState("");

  useEffect(() => {
    const script = document.createElement("script");

    script.src = globalData.apiUrl + "/front/js/app-html.js";
    // document.body.appendChild(script);

    script.onload = function () {
      // THEME.header.stickyHeaderInit(".hdr-content-sticky");
    };
  }, []);
  useEffect(() => {
    let pathName = location.pathname;
    document.title = slugToTitle(pathName);
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(pathName);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);

    // debugger;
    // let pathNameArray = pathName.split("-");
    // let popped = pathNameArray.pop();
    // let newPathName = pathNameArray.join("-");
    // axios.get(`http://77.68.127.197/twynhams-tours/api/events${newPathName}`)
    // alert("in Event");
    // setloading(false);
    // return false;
    axios
      .get(globalData.apiUrl + `/api/events${pathName}`)

      .then((response) => {
        window.scrollTo(0, 0);
        if (response.data.data.isEventTypeExist == undefined) {
          if (response.data.data.length != undefined) {
            setData([...response.data.data]);
            setloading(false);
          }
        } else if (response.data.data.isEventTypeExist == true) {
          // console.log("gggggggg");
          setData([]);
          setMessage(response.data.message);
        } else {
          window.location.href = globalData.redirectUrl + "not-found";
        }
      })
      .catch(function (error) {
        // console.log(error)
      });
  }, [location]);

 const slugToTitle = (slug) => {
    // Split the slug by hyphens to get individual words
    slug = slug.replace('/','');
    // slug = slug.charAt(0).toUpperCase();
    // console.log(slug);
    const words = slug.split('-');
    // console.log(words);
    // Capitalize the first letter of each word and join them with spaces
    const title = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  
    return  title;
  }
  // console.log(location.state.id);
  return (

    <>
      <div id="calender" className="bg-dark">
        <div className="container-fluid">

        { loading ?
              <Loading />
              : ''
          }
          {Data.length > 0 ? (
            <div className="row">
              {Data.map((ev, index) => {
                return (
                  <div className="col-lg-3 col-sm-6 col-xs-12 align-items-stretch my-2" key={ev.id}>
                    <div className="card text-center calender-p1-bg text-white mb-3">
                      <img
                        src={ev.imageThumbnailUrl}
                        className="card-img-top rounded-0"
                        alt="..."
                      />
                      <div className="card-body">
                        <div className="flag-with-title d-flex justify-content-center align-items-center">
                          <div className="calender-flag">
                            <img
                              src={ev.countryImageUrl}
                              className="img-fluid"
                            />
                          </div>
                          <h5 className="card-title text-white mb-md-1 mx-2">
                            {ev.title} / {ev.countryName}
                          </h5>
                        </div>
                        <h3 className="card-title text-white calener-p-main-title">
                          {ev.event}
                        </h3>
                        <hr className="bg-white" />
                        <p className="card-text mt-md-1">
                          {ev.listing_description}
                        </p>
                        <h5 className="card-title text-white mb-md-0 mt-md-2 tourincludes-title">
                          Tour includes:
                        </h5>
                        {ReactHtmlParser(ev.listing_tour_includes)}

                        {/* <ul>
                                    <li><a href="#">4-nights 4* hotel accommodation</a></li>
                                    <li><a href="#">Brooklands Museum & Mercedes Benz World entry</a></li>
                                    <li><a href="#">Vintage Belmond Pullman to Goodwood on Saturday</a></li>
                                    <li><a href="#">VIP Hospitality & paddock access on Sunday</a></li>
                                    <li><a href="#">All circuit transfers and complimentary programme</a></li>
                                    <li></li>
                                </ul> */}
                        <h6 className="card-title text-white mb-md-0 mt-md-2 tourincludes-title">
                          {ev.eventDateFormatted}
                        </h6>
                      </div>
                      {ev.event_status == 1 ? (
                        <Link
                          to={ev.eventInfoSlugUrl}
                          state={{ id: ev.idEncrypt }}
                          className="btn btn-primary btn-block rounded-0"
                        >
                          {ev.eventStatusButton}
                        </Link>
                      ) : ev.event_status == 2 ? (
                        <Link
                          to={ev.eventInfoSlugUrl}
                          className="btn bg-red btn-block rounded-0"
                        >
                          {ev.eventStatusButton}
                        </Link>
                      ) : (
                        <Link
                          to={ev.eventInfoSlugUrl}
                          className="btn bg-green btn-block rounded-0"
                        >
                          {ev.eventStatusButton}
                        </Link>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="row">
              <h2 className="text-center text-light m-auto">{message}</h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Event;
