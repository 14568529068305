import React, { Component } from "react";
import { useParams,  Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { Form, Card, Button } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import Cookies from "universal-cookie";
import globalData from "../config.json";
import Loading from "./Loading";
import queryString from 'query-string';
import axios from "axios";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


const cookies = new Cookies();


class Invoicedetail extends Component {
  constructor(props) {
    super(props);
    // console.log(props, 'invoice detail');
    this.state = {
      loading: true,
      agree: false,
      showError: false,
      invoiceData: '',
      redirectPaymentUrl: '',
      ipaddress: '',
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }


  loadScript() {
    let scripts = [{ src: globalData.apiUrl + "/front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });
  }
  componentDidMount() {

    // console.log(this.props);

    // this.getPaymentResponce(queryString.parse(this.props.location.search));
    // window.scrollTo(0, 0);
    this.loadScript();
    document.title = 'Invoice Detail';
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);

    if(localStorage.getItem("invoice_token"))
    {
      this.getData();
      this.getinvoiceData();
    }else{
      window.location.href= globalData.redirectUrl+'invoice';
    }
        // if (localStorage.getItem("token"))
    //   this.getAuth(localStorage.getItem("token"));

    
    // window.addEventListener("scroll", this.handleScroll);
  }

  handleChange(event) {
    // console.log(event.target.value);
    const { name, value } = event.target;
    
      this.setState({
        [name]: event.target.checked,
       });

  }

  getData(){
    axios
      .get(globalData.apiUrl + "/api/getIpaddress", {})
      .then((result) => {

        this.setState({
          ipaddress: result.data.data,
         });
        // setip(result.data.data);
      })
      .catch((error) => {});
  };

  getinvoiceData(){
   
    let token = "";
    let userObject = {
      "Browser CodeName": window.navigator.appCodeName,
      "Browser Name": window.navigator.appName,
      "Browser Version": window.navigator.appVersion,
      "Cookies Enabled": window.navigator.cookieEnabled,
      "Browser Language": window.navigator.language,
      "Browser Online": window.navigator.onLine,
      Platform: window.navigator.platform,
      "User-agent header": window.navigator.userAgent,
    };
    if (localStorage.getItem("invoice_token")) {
      // console.log(localStorage.getItem("token"));
      token = localStorage.getItem("invoice_token");
      let config = {
        headers: {
          "Content-Type": "application/json",
        
        },
      };

      axios
      .post(
        globalData.apiUrl + "/api/invoice/order",
        {
          tokenInvoice:token,
          invoiceNumber: localStorage.getItem("invoiceNumber"),
          customerName: this.props.invoice !== undefined ? this.props.invoice.client_surname : "",
          ipAddress: this.state.ipaddress,
          userAgent: JSON.stringify(userObject),
         
        },
        config
      )
      .then((result) => {

        // console.log(result.data.data.invoiceInfo);
        //  return false;
          if(typeof result.data.data.invoiceInfo !== undefined ){
            this.setState({
              invoiceData: result.data.data.invoiceInfo,
              redirectPaymentUrl:result.data.data.barclaysRedirectUrl,
              loading: false,
            });
        }
      })
      .catch((err) => console.error(err));
    }
    
    // console.log(config);
    
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }

  handleClick(e) {
    // console.log(this.state.agree);
    if (this.state.agree) {

      window.open(this.state.redirectPaymentUrl, '_self', 'noopener,noreferrer')
      // window.location.href = this.state.redirectPaymentUrl;
     
    } else {
      this.setState({
        showError: true,
      });
     
      // console.log('ggggggggggggggg');
    }

  }

  componentDidUpdate() {}

  componentWillUnmount() {
    const script = document.getElementById("myScript");
    script.parentNode.removeChild(script);
  }

  render() {
    if(localStorage.getItem('invoice_token'))
    {
      // console.log( this.state.invoiceData.installmentMessage);
      return (
        <>
          { this.state.loading ?
              <Loading />
              : ''
          }
          <div className="page-content">
            <div id="checkout" className="bg-dark">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-9 p-0 mt-3 mb-2">
                    <div className="card px-0 for-p-5 pb-0 mt-3 mb-3">
                      <h2 id="heading">Secure Balance Payment system </h2>
                      
                      <p className="m-0"><strong>Reference:</strong>{this.state.invoiceData.invoice_reference} </p>
                      <p className="m-0"><strong>Surname:</strong> {this.state.invoiceData.client_surname} </p>
                      <p className="m-0"><strong>Outstanding Amount:</strong> {this.state.invoiceData.balanceAmountFormatted }</p>
                      {  this.state.invoiceData.installmentMessage ?
                      <p className="m-0">
                      {ReactHtmlParser(
                                    this.state.invoiceData.installmentMessage.message
                                  )}</p>
                                  : ""
                      }
                      <div className="form-check p-0 mt-5">
                          <input
                              type="checkbox"
                              className="form-check-input"
                              name="agree"
                              onChange={this.handleChange}
                              id="exampleCheck1"
                          />
                      <label className="form-check-label" htmlFor="exampleCheck1">
                          <strong>
                          I confirm that the outstanding balance shown above is correct and I agree to the {" "}
                          <Link
                              to={"/terms-and-conditions"}
                              target="_blank"
                              className="link-color"
                          >
                              Terms and conditions
                          </Link>
                          </strong>
                      </label>
                      {this.state.showError ? (
                        <Form.Text style={{ color: "red" }}>
                             Please accept the Terms and Conditions
                        </Form.Text>
                      ) : (
                        ""
                      )}
                    </div>
                    <p style={{ margin: "10px 0"}}>To make your payment by credit card click the 'Proceed to Barclaycard' button below to navigate to our secure payment gateway.</p>
                    <p style={{ margin: "10px 0" }}>You will receive payment confirmation by email within 24 hours. If you do not receive confirmation please check your spam/junk folders in the first instance and then call our helpline on 0800 5999 077.</p>
                    <img alt="Barclays Visa/Mastercard logo" title="Barclays Visa/Mastercard"  className="barclay-img-invoice" src={`${globalData.baseUrl}front/images/barclays_visa_mastercard_amex.jpg`} />
                      <br />
                    <button type="button" onClick={(e) => this.handleClick(e)} className="btn btn--md font-weight-bold float-left mr-auto" >Proceed to Barclays</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }else{
      return(<Link to='/'  />);
    }
  }
}

const mapStateToProps = (state) => {

  // console.log(state, 'invoice-deatoil');
  return {
    cartCount: state.cart_count,
    userData: state.user_data,
    invoice:state.invoice_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
    addInvoice: (invoice) => dispatch({type: "INVOICE", value: invoice}),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoicedetail);
