import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Collapse from "react-bootstrap/Collapse";
import Cookies from "universal-cookie";
import globalData from "../config.json";
import axios from "axios";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';



const cookies = new Cookies();
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    const location = useLocation();

    return <Children {...props} match={match} location={location}/>;
  };
}

class OrderSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      order: {},
      orderitem: [],
    };
    this.handleScroll = this.handleScroll.bind(this);
  }


  
  loadScript() {
    let scripts = [{ src: globalData.apiUrl + "/front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });
  }
  componentDidMount() {
    // window.scrollTo(0, 0);
   
    if (localStorage.getItem("token"))
      this.getAuth(localStorage.getItem("token"));
      this.loadScript();
      this.getOrderData();
      ReactGA.initialize(globalData.googleAnalyticKey);
      ReactGA.pageview(window.location.pathname);
      ReactPixel.init(globalData.metaFacebookKey);
      ReactPixel.pageView(window.location.pathname);

    // window.addEventListener("scroll", this.handleScroll);
  }

  getAuth(token) {
    axios
      .get(globalData.apiUrl + "/api/auth", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        this.setState({
          user: result.data.user,
        });
      })
      .catch((error) => {
        this.logout();
      });
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }

  handleClick(e) {}

  getOrderData() {
    if (localStorage.getItem("token")) {
      axios
        .get(globalData.apiUrl + `/api/order/${this.props.match.params.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          this.setState({
            order: { ...response.data.data.order },
            orderitem: [...response.data.data.orderItems],
          });
        })
        .catch(function (error) {
          // console.log(error)
        });
    } else if (
      cookies.get("guesttoken") != undefined &&
      cookies.get("guesttoken") != ""
    ) {
      axios
        .get(
          globalData.apiUrl +
            `/api/order/${this.props.match.params.id}?paramId=${cookies.get(
              "guesttoken"
            )}`,
          {}
        )
        .then((response) => {
          this.setState({
            order: { ...response.data.data.order },
            orderitem: [...response.data.data.orderItems],
          });
        })
        .catch(function (error) {
          // console.log(error)
        });
    } else {
      window.location.href = globalData.redirectUrl + "not-found";
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    const script = document.getElementById("myScript");
    script.parentNode.removeChild(script);
  }

  render() {
    return (
      <>
        <div className="page-content">
          <div id="order-success" className="bg-dark for-padding-review">
            <section id="sec-2" className="pt-0">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 p-0">
                    <div className="success-icon">
                      <div className="main-container">
                        <div className="check-container">
                          <div className="check-background">
                            <svg
                              viewBox="0 0 65 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7 25L27.3077 44L58.5 7"
                                stroke="white"
                                strokeWidth={13}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="check-shadow" />
                        </div>
                      </div>
                      <div className="success-contain">
                        <h4>Order Success</h4>
                        <h5 className="font-light">
                          Thank you for your payment, you will shortly receive
                          an automated email confirmation with details of your
                          order or balance payment received.
                        </h5>
                        <h5 className="font-light">
                          Should you have any questions in the meantime please
                          call our Head Office on 0800 5999 077 or 00 44 7739
                          944339.
                        </h5  >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="sec-1"
              className="section-b-space cart-section order-details-table"
            >
              <div className="container">
                <div className="title title1 title-effect mb-1 title-left">
                  <h2 className="mb-3">Order Details</h2>
                </div>
                <div className="row g-4">
                  <div className="col-md-7">
                    <div className="col-sm-12 table-responsive">
                      <table className="table cart-table-order-success table-borderless">
                        <tbody>
                          {this.state.orderitem.map((od, index) => {
                            return (
                              <>
                                <tr className="table-order" key={index}>
                                  {/* <td>
                                    <a>
                                      <img
                                        src={od.imageThumbnailUrl}
                                        className="img-fluid blur-up lazyloaded"
                                      />
                                    </a>
                                  </td> */}
                                  <td>
                                    <p className="fw-bold">Package Name</p>
                                    <h5>{od.item_name}</h5>
                                    {od.meta.is_installments == 1 ? (
                                      <div className="product-name">
                                        {" "}
                                        <a href="#">Instalment</a>
                                        <div className="product-info">
                                          <div>
                                            {ReactHtmlParser(
                                              od.installment_data_raw
                                                .installmentsMessage
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <p className="fw-bold">Quantity</p>
                                    <h5>{od.quantity}</h5>
                                  </td>
                                  <td>
                                    <p className="fw-bold">Price</p>
                                    <h5>£{od.unit_price}.00</h5>
                                  </td>
                                </tr>
                                { od.orderItemSupplement ?
                                  <tr className="table-order" key={od.id}>
                                    {/* <td></td> */}
                                    <td>
                                      {od.meta.is_supplements == 1 ? (
                                        <>
                                          <p className="fw-bold">Supplement </p>
                                          <h5>
                                            {
                                              od.orderItemSupplement
                                                .installment_data_raw
                                                .supplements_p1
                                            }
                                            &nbsp;&nbsp;
                                            {
                                              od.orderItemSupplement
                                                .installment_data_raw
                                                .supplements_p2
                                            }
                                            &nbsp;
                                            {
                                              od.orderItemSupplement
                                                .installment_data_raw
                                                .supplements_p3
                                            }
                                          </h5>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {od.meta.is_installments == 1 &&
                                      od.orderItemSupplement ? (
                                        <div className="product-name">
                                          {" "}
                                          <a href="#">Instalment</a>
                                          <div className="product-info">
                                            <div>
                                              {ReactHtmlParser(
                                                od.orderItemSupplement
                                                  .installment_data_raw
                                                  .installmentsData
                                                  .installmentsMessage
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      <p className="fw-bold">Quantity</p>
                                      <h5>{od.orderItemSupplement.quantity}</h5>
                                    </td>
                                    <td>
                                      <p className="fw-bold">Price</p>
                                      <h5>
                                        £{od.orderItemSupplement.unit_price}.00
                                      </h5>
                                    </td>
                                  </tr>
                                  : ''
                                }
                              </>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr className="table-order">
                            <td colSpan={3}>
                              {/* <h5 className="font-light">Subtotal :</h5> */}
                            </td>
                            <td>
                              {/* <h4 className="fw-bold">
                                £{this.state.order.subtotal_amount}
                              </h4> */}
                            </td>
                          </tr>
                          {/* <tr className="table-order">
                            <td colSpan={3}>
                              <h5 className="font-light">Shipping :</h5>
                            </td>
                            <td>
                              <h4 className="fw-bold">£00.00</h4>
                            </td>
                          </tr>
                          <tr className="table-order">
                            <td colSpan={3}>
                              <h5 className="font-light">Tax(GST) :</h5>
                            </td>
                            <td>
                              <h4 className="fw-bold">£00.00</h4>
                            </td>
                          </tr> */}
                          { this.state.order.include_installment == 1 ? 
                          <tr className="table-order">
                            <td colSpan={2}>
                              <h4 className="theme-color fw-bold tour-color">
                                First Instalment Price :
                              </h4>
                            </td>
                            <td>
                              <h4 className="theme-color fw-bold tour-color">
                                £{this.state.order.payable_amount}
                              </h4>
                            </td>
                          </tr>
                          : ''
                         }
                          <tr className="table-order">
                            <td colSpan={2}>
                              <h4 className="theme-color fw-bold tour-color">
                                Total Price :
                              </h4>
                            </td>
                            <td>
                              <h4 className="theme-color fw-bold tour-color">
                                £{this.state.order.total_subtotal}
                              </h4>
                            </td>
                          </tr>
                         
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  {this.state.order != "" ? (
                    <div className="col-md-5">
                      <div className="order-success">
                        <div className="row g-4">
                          <div className="col-sm-6">
                            <h4>Name</h4>
                            <ul className="order-details">
                              <li>
                                {this.state.order.title}{" "}
                                {this.state.order.first_name}{" "}
                                {this.state.order.last_name}
                              </li>
                            </ul>
                            <h4>date</h4>
                            <ul className="order-details">
                              <li>
                                {this.state.order.orderDateTimeFormatted}
                              </li>
                            </ul>
                            <h4>Email</h4>
                            <ul className="order-details">
                              <li>{this.state.order.email}</li>
                            </ul>
                            <h4>Contact</h4>
                            <ul className="order-details">
                              <li>{this.state.order.telephone}</li>
                            </ul>
                            {/* <h4>payment method</h4>
                            <ul className="order-details">
                              <li>Barclays</li>
                            </ul> */}
                          </div>
                          <div className="col-sm-6">
                            <h4>Billing Address</h4>
                            <ul className="order-details">
                              <li>
                              {this.state.order.line1}<br />
                              {this.state.order.line2}<br />
                              {this.state.order.line3}<br />
                              </li>
                            </ul>
                            <h4>Town / City</h4>
                            <ul className="order-details">
                              <li>
                                {this.state.order.town}
                               
                              </li>
                            </ul>
                            <h4>County</h4>
                            <ul className="order-details">
                              <li>
                                {this.state.order.county}
                              </li>
                            </ul>
                            <h4>Postcode</h4>
                            <ul className="order-details">
                              <li>{this.state.order.postcode}</li>
                            </ul>
                            <h4>Country</h4>
                            <ul className="order-details">
                              <li>{this.state.order.country}</li>
                            </ul>
                            
                            {/* <h4>Promo used</h4>
                            <ul className="order-details">
                              <li>N/A</li>
                            </ul> */}
                          </div>
                          {/* <div className="col-md-12">
                            <div className="delivery-sec">
                              <h6 className="text-white mb-0">
                                We will confirm receipt of the next payment as
                                soon as it is received by our bank
                              </h6>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row success-contain">
                  <div className="col-md-9">
                    {/* <h5 className="font-light pl-3 text-white font-weight-normal">
                      Please Note:
                    </h5> */}
                    <h5 className="font-light my-2 pl-3 text-white line-height  font-weight-normal">
                      {/* All payments must be made on the specified dates, using
                      the credit card that was made for the initial payment. If
                      you have any problems with your card or bank and need to
                      amend the payments please do not hesitate to contact our
                      customer service by email at
                      mailto:sales@twynhamstours.co.uk or by phone on 0800 5999
                      077 (+44 800 5999 077). */}
                      {/*   Please Note: For scheduled payment orders, all payments must be made on the specified dates, using the credit card that was made for the initial payment. If you have any problems with your card or bank and need to amend the payments, please do not hesitate to contact our customer service team, by email at sales@twynhamstours.co.uk or by phone on 0800 5999 077 (+44 800 5999 077). */}
                      Please Note: For scheduled payment orders, all payments will be taken automatically on the specified dates, using the credit card that was made for the initial payment. If you have any problems with your card or bank and need to amend the payments, please do not hesitate to contact our customer service team, by email at sales@twynhamstours.co.uk or by phone on 0800 5999 077 (+44 800 5999 077).
                    </h5>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartCount: state.cart_count,
    userData: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderSuccess));
