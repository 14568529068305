import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import globalData from "../config.json";

class Submenu extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      event: [],
    };
  }

  componentDidMount() {
    this.getEvents();
  }

  getEvents() {
    axios.get(globalData.apiUrl + "/api/event-types").then((response) => {
      this.setState({
        event: [...response.data.data],
        loading: false,
      });

      // console.log(this.state.event);
    });
  }

  render() {
    return (
      <div className="mmenu-submenu mmenu-submenu--has-bottom" >
        <div className="mmenu-submenu-inside">
          <div className="container">
            <div
              className="card-columns"
              style={{ backgroundColor: "#00a0df" }}
            >
              {this.state.event.map((e, index) => {
                return (
                  <div className="card" key={e.id}>
                    <div key={index}
                      className="card-body"
                      style={{ backgroundColor: "#00a0df" }}
                    >
                      { e.events.length > 0 ?
                        <h5 className="card-title" style={{ color: "#fff" }}>
                          <Link to={`/${e.handle}`} onClick={this.closeMegaMenu}>
                            {e.name}
                          </Link>
                        </h5>
                        : ''
                      }
                      {e.events.map((ev, index) => {
                        return (
                          <ul
                            style={{ listStyleType: "none", padding: "0" }}
                            key={ev.id}
                          >
                            <li key={index}>
                              {ev.event_status == 1 ? (
                                <Link
                                  to={`/${e.handle}/${ev.handle}`}
                                  state={{
                                    id: e.idEncrypted,
                                    event_id: ev.idEncrypted,
                                  }}
                                  onClick={this.closeMegaMenu}
                                >
                                  {ev.event}
                                </Link>
                              ) : (
                                <Link to="">{ev.event}</Link>
                              )}
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeUser: () => dispatch({ type: "USER", value: "guest" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Submenu);
