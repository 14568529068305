import React from 'react';
// import logo from './logo.svg';
import './App.css';
// import Header from './Header'
import Main from './Main'
import './analytics'; // Import the analytics configuration
// import Footer from './Footer'

function App() {
  return (
     <div>
        
        {/* <Header/> */}
        
        <Main/>
      
        {/* <Footer/> */}
    </div>
  );
}

export default App;
