import React from "react";
import { Link } from "react-router-dom";

function CartEmpty() {
  return (
    <>
      <div className="page-content">
        <div id="empty-cart" className="bg-dark">
          <section className="shopping-cart dark">
            <div className="container">
              <div className="block-heading">
                <h1>MY BASKET</h1>
              </div>
              <div className="content">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div
                      className="items"
                      style={{
                        backgroundImage: "url('front/images/cart-bg.png')",
                      }}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card border-0">
                              <div className="card-body cart">
                                <div className="col-sm-12 empty-cart-cls text-center">
                                  <img
                                    src="/twynhams-tours/public/front/images/empty-cart.svg"
                                    className="img-fluid"
                                  />
                                  <h1 className="mb-3 theme-font-color">
                                    <strong className="font-weight-bold">
                                      Whoops! Your basket is empty !
                                    </strong>
                                  </h1>
                                  {/* <h4>Add something to make us happy :)</h4>
                                  <Link
                                    to="#"
                                    className="btn cart-btn-transform m-3"
                                    data-abc="true"
                                  >
                                    continue shopping
                                  </Link> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default CartEmpty;
