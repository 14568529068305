import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Collapse from "react-bootstrap/Collapse";
import Swal from "sweetalert2";
import globalData from "../config.json";
import Cookies from 'universal-cookie';
import axios from "axios";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


const cookies = new Cookies();

class Invoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // user: "",
      invoice: "",
      invoice_no: "",
      surname: "",
      loading: false,
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }


  loadScript() {
    let scripts = [{ src: globalData.appUrl + "front/js/app-html.js" }];

    //Append the script element on each iteration
    // console.log(" Contact Loadscript)", scripts);
    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });

    const script = document.getElementById("myScript");
    if (script === undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadScript();
    window.addEventListener("scroll", this.handleScroll);
    document.title = 'Invoice Login';
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);

    // this.getPrivacyPolicy();
  }

  componentDidUpdate() {}

  componentWillUnmount() {}


  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();
    
    if (this.validateForm()) {
      this.setState({  
        loading: true
      });
     
    let token = "";
    let guesttoken = "";
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
      config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
    } else {
      guesttoken = cookies.get("guesttoken");
    }
    // console.log(config);
    axios
      .post(
        globalData.apiUrl + "/api/invoice/login",
        {
          invoiceNumber: this.state.invoice_no,
          customerName: this.state.surname,
          paramId: guesttoken,
        },
        config
      )
      .then((result) => {

        //  console.log(result.data.data.length, result.data.data.length > 0);
        //  console.log(typeof result.data.data.invoiceInfo !== undefined && result.data.data.invoiceInfo.length > 0);

        //  return false;
          if( result.data.data.length == undefined){
            // console.log(result.data);
            if (typeof result.data.data.invoiceInfo !== undefined ) {
              // console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhh');
               localStorage.setItem("invoice_token", result.data.data.invoiceInfo.token_invoice);
                // this.props.addUser(result.data.user);
            }
            this.props.addInvoice(result.data.data.invoiceInfo);
            localStorage.setItem("invoiceNumber", this.state.invoice_no);
            localStorage.setItem("customerName", this.state.surname);
            // console.log(this.props, 'eventinvoice');
            // if (localStorage.getItem("token")) {
            //    
            // }
        }else{
          // console.log('hhhhhhhhhhhhhhhh');
          Swal.fire(result.data.message, "", "error");
        }
      })
      .catch((err) => console.error(err));
    }
  }

  validateForm(){
    // let errors = {};
    let formIsValid = true;
    let Formerrors = [];
    if (!this.state.invoice_no) {
      formIsValid = false;
      Formerrors["invoice_no"] = "*Please enter your invoice Number.";
    }

    // if (!this.state.surname) {
    //   formIsValid = false;
    //   Formerrors["surname"] = "*Please enter your Customer Name.";
    // }

    this.setState({
      errors: Formerrors
    });
    return formIsValid;
  };

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }

  render() {
    // console.log(this.props.invoice != '' );
    // return false;
    if(this.props.invoice != '' && this.props.invoice !== undefined)
    {
      return(<Navigate to='/invoice-detail'  />);
    }
    else{
    return (
      <>
         <div id="main-wrapper" className="tour-login bg-dark for-padding tour-invoice-mobile">
            <div className="container">
                <div className="row g-0  py-4 py-md-5">
                <div className="col-lg-7 shadow-lg px-0">
                    <div className="hero-wrap d-flex align-items-center rounded-3 rounded-end-0 h-100">
                    <div className="hero-mask opacity-9 bg-primary" />
                    <div className="hero-bg hero-bg-scroll" style={{backgroundImage: 'url("./images/login-bg.jpg")'}} />
                    <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                        <div className="row g-0 my-auto d-flex justify-content-center">
                        <div className="col-10 col-lg-10 p-0">
                            <h1 className="text-10 text-white mb-1">SECURE BALANCE PAYMENT SYSTEM</h1>
                            <p className="text-5 text-white lh-base">Please enter your original invoice number and surname in the boxes below and click the 'Confirm Balance' button to view your oustanding account balance.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-5 shadow-lg d-lg-block d-sm-flex d-xs-flex align-items-center rounded-3 rounded-start-0 bg-dark justify-content-center">
                    <div className="px-lg-5 py-md-5 for-responsive-space">
                    <div className="px-lg-4">
                        <form id="loginForm" className="form-dark" method="post" onSubmit={this.handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label text-light" htmlFor="emailAddress">Invoice Number</label>
                            <input type="text" className="form-control" name="invoice_no"  onChange={this.handleChange} value={this.state.invoice_no} autoComplete="new-password"  id="emailAddress" placeholder="Enter Invoice Number" />
                            {this.state.errors.invoice_no && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                <strong>{this.state.errors.invoice_no}</strong>
                              </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className="form-label text-light" htmlFor="loginPassword">Customer Name</label>
                            <input type="text" className="form-control" id="loginPassword" onChange={this.handleChange} value={this.state.surname} autoComplete="new-password" name="surname"  placeholder="Enter Customer Name" />
                            {this.state.errors.surname && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                <strong>{this.state.errors.surname}</strong>
                              </div>
                            )}
                        </div>
                        <div className="d-grid my-4">
                            <button className="btn btn-primary btn-block" type="submit">Confirm Balance</button>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

      </>
    );
  }
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    user: state.user_data,
    invoice:state.invoice_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (user) => dispatch({ type: "USER", value: user }),
    addInvoice: (invoice) => dispatch({type: "INVOICE", value: invoice}),
    removeUser: () => dispatch({ type: "USER", value: "guest" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
