import React, { Component } from "react";
// import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
// import Collapse from "react-bootstrap/Collapse";
// import Swal from "sweetalert2";
import globalData from "../config.json";
import Loading from "./Loading";
import axios from "axios";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

class AboutUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dataImages: [],
      errors: [],
      loading: true,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  loadScript() {
    window.scrollTo(0, 0);

    let scripts = [{ src: globalData.appUrl + "front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });

    const script = document.getElementById("myScript");
    if (script === undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      // console.log(" script33 ", script);
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    }
  }

  loadScript1 = () => {
    let scripts = [{ src: globalData.appUrl + "front/js/app-html.js" }];

    //Append the script element on each iteration
    // console.log(" Contact Loadscript)", scripts);
    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });

    const script = document.getElementById("myScript");
    if (script === undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });

    }
  };
  componentDidMount() {
    // window.scrollTo(0, 0);
    /* let scripts = [{ src: globalData.apiUrl + "/front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    }); */
    // window.addEventListener("scroll", this.handleScroll);
    document.title = 'About Us';
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);
   
    
    this.loadScript();
    this.getAboutus();
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    //const script = document.getElementById("myScript");
    //script.parentNode.removeChild(script);
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }
  getAboutus() {
    axios
      .post(globalData.apiUrl + "/api/getCmsData", {
        slug: "about-us",
      })
      .then((response) => {
        // console.log(response);
        this.setState({
          data: response.data.data,
          dataImages: [...response.data.dataImages],
          loading: false,
        });
        // console.log(this.state.data);
      });
  }

  render() {
    return (
      <>
        <div id="main-wrapper" className="tour-aboutus bg-dark">
          {this.state.loading ? <Loading /> : ""}
          {this.state.dataImages ? (
            <div className="slideshow">
              {this.state.dataImages.map((img) => (
                <img key={img.id} src={`${img.imageUrl}`} alt="aboutimage" />
              ))}
            </div>
          ) : (
            ""
          )}
          {this.state.data ? (
            <div className="page-content">
              <div className="container pb-4 text-light">
                <h1 className="text-light">{this.state.data.title}</h1>
                {ReactHtmlParser(this.state.data.html)}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default AboutUs;
