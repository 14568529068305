const initialState = {
    user_data: '',
    invoice_data: '',
    categoryId: '',
    eventId: '',
    show_modal: false,
    show_login: false,
    product_id: '',
    cart_count: 0,
    cart_id: 0,
    cart_item_count: 0,
    wishlist_count: 0,
    toast_show: false,
    toast_message: '',
    alert_show: 0,
    
}

const reducer = (state = initialState, action) => {
    
    switch ( action.type ) {
        case 'USER':
            return {
                user_data: action.value
            }
        case 'INVOICE':
            return {
                ...state,
                invoice_data: action.value
            }
        case 'MODAL_CONTROL':
            return {
                ...state, 
                show_modal: action.value
            }
        case 'LOGIN_CONTROL':
            return {
                ...state, 
                show_login: action.value
            }
        case 'QUICKVIEW_CONTROL':
            return {
                ...state,
                product_id: action.value,
                show_modal: true
            }
        case 'CART_COUNT':
            return {
                 ...state,
                cart_count: action.value
            }
        case 'CART_ITEM_QUANTITY':
            return {
                ...state,
                cart_item_count: action.value
            }
        case 'CART_ID':
            return {
                ...state,
                cart_id: action.value
            }
        case 'CATEGORY_SLUG':
            return {
                ...state,
                categoryId: action.value
            }
        case 'EVENT_SLUG':
            return {
                ...state,
                eventId: action.value
            }
        case 'WISHLIST_COUNT':
            return {
                ...state,
                wishlist_count: action.value
            }
        case 'SHOW_TOAST':
            return {
                ...state,
                toast_show: true,
                toast_message: action.value
            }
        case 'HIDE_TOAST':
            return {
                ...state,
                toast_show: false
            }
        case 'HIDE_SWEETALERT':
            return {
                ...state,
                alert_show: action.value
            }
    }

    return state
}

export default reducer