import React from "react";
import { Link } from "react-router-dom";
import globalData from '../config.json'
import ReactPixel from 'react-facebook-pixel';


function OrderList(props) {

    // console.log(props);
  return (
    <div>
    <div className="table-responsive">
     <table className="shop_table my_account_orders table">
        <thead>
            <tr>
            <th className="order-number">Order</th>
            <th className="order-date">Date</th>
            <th className="order-status">Order Type</th>
            <th className="order-total">Total</th>
            <th className="order-actions">Actions</th>
            </tr>
        </thead>
        <tbody>
        { (props.orderList && props.orderList.length > 0) ?
            
            props.orderList.map((od, index) => {
                return(
                <tr className="order" key={index}>
                <td className="order-number" data-title="Order"><a href="#">#{od.confirmation_id}</a></td>
                <td className="order-date" data-title="Date"><time dateTime="2014-06-12">{od.orderDateTimeFormatted}</time></td>
                <td className="order-status" data-title="Status">{od.paymentMode}</td>
                <td className="order-total" data-title="Total">{od.totalText}</td>
                <td className="order-actions" data-title="Action"><a style={{ cursor: "pointer" }} onClick={(e) => props.handleClick(e, od.confirmation_id)} className="button view">View</a></td>
                </tr>
                )
                }
                )
            
            : (<tr className="order" key={1}><td className="w-100 text-white font-weight-bold text-center" colSpan={4}>No orders found</td></tr>)
            }
        </tbody>
    </table>
    </div>
    </div>
  );
}
export default OrderList;
