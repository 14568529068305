import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import Authentfication from "./auth/Authentification";
import Submenu from "./includes/Submenu";
import { connect } from "react-redux";


class SubHeader extends Component {
    constructor(props) {
        super(props);
        // console.log(props);
      }
    render() {

        // console.log(this.props);
        return (
                <ul className="mmenu mmenu-js">
                <li>
                <NavLink to="/" activeclassname="active">
                    Home
                </NavLink>
                </li>
                
                <li>
                <NavLink to="/about-us" activeclassname="active">
                    About Us
                </NavLink>
                </li>
                <li className="mmenu-item--mega">
                <a activeclassname="active">Tours </a>
                <Submenu />
                </li>
                {/* <li>
                <NavLink to="/our-fleet" activeclassname="active">
                    Our Fleet 
                </NavLink>
                </li> */}
                <li className="mmenu-item--mega">
                <NavLink to="/reviews" activeclassname="active">
                    Reviews
                </NavLink>
                </li>
                <li>
                <a
                    className="dropdn-link js-dropdn-link minicart-link my-1 cursor-pointer"
                    data-panel="#dropdnMinicart"
                >
                     View Basket
                         <span className="ml-1">
                         ({this.props.cartCount > 0
                        ? this.props.cartCount
                        : "0"})
                        </span>
                  
                </a>
                </li>
                {/* <Authentfication /> */}
                <li>
                <a
                    rel="nofollow"
                    title="Mailing List"
                    href="https://mailchi.mp/twynhamstours/mailing_list"
                    target="_blank"
                >
                    Mailing List
                </a>
                </li>
                <li>
                <NavLink to="/contact-us" activeclassname="active"   className="last-child-for-border-remove">
                    Contact Us
                </NavLink>
                </li>
                
                
                
            </ul>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      cartCount: state.cart_count,
      cart_item_count: state.cart_item_count,
      userData: state.user_data,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
      updateCartQuantity: (quantity) => dispatch({ type: "CART_ITEM_QUANTITY", value: quantity }),
  
    };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
// export default SubHeader;

