import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { Form, Card, Button } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import Cookies from "universal-cookie";
import globalData from "../config.json";
import Loading from "./Loading";
import queryString from 'query-string';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';



const cookies = new Cookies();


class InvoiceSucess extends Component {
  constructor(props) {
    super(props);
    // console.log(props, 'invoice detail');
    this.state = {
      loading: false,
      agree: false,
      showError: false,
      invoiceData: '',
      redirectPaymentUrl: '',
      ipaddress: '',
    };
    this.handleScroll = this.handleScroll.bind(this);
    // this.handleChange = this.handleChange.bind(this);

  }

  loadScript() {
    let scripts = [{ src: globalData.apiUrl + "/front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });
  }


  componentDidMount() {

    // console.log(this.props);

    // this.getPaymentResponce(queryString.parse(this.props.location.search));
    // window.scrollTo(0, 0);
    this.loadScript();
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);

    //   this.getinvoiceData();
        // if (localStorage.getItem("token"))
    //   this.getAuth(localStorage.getItem("token"));

    
     window.addEventListener("scroll", this.handleScroll);
  }


  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }


  componentDidUpdate() {}

  componentWillUnmount() {
    const script = document.getElementById("myScript");
    script.parentNode.removeChild(script);
  }

  render() {
    return (
      <>
        { this.state.loading ?
            <Loading />
            : ''
        }
        <div className="page-content">
          <div id="checkout" className="bg-dark">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-9 p-0 mt-3 mb-2">
                  <div className="card px-0 for-p-5 pb-0 mt-3 mb-3">
                    <h2 id="heading">Payment Accepted</h2>
                    <p>Thank you for your payment, you will shortly receive an automated email confirmation with details of your order or balance payment received.</p>

                    <p>Should you have any questions in the meantime please call our Head Office on 0800 5999 077 or 00 44 7739 944339.</p>

                    
                    
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {

  // console.log(state, 'invoice-deatoil');
  return {
    cartCount: state.cart_count,
    userData: state.user_data,
    invoice:state.invoice_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
    addInvoice: (invoice) => dispatch({type: "INVOICE", value: invoice}),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceSucess);
