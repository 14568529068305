import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Collapse from "react-bootstrap/Collapse";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import Order_detail from "./Order_detail";
import OrderList from "./OrderList";
import globalData from "../config.json";
import Loading from "./Loading";
import axios from "axios";
import $ from 'jquery';
import ReactPixel from 'react-facebook-pixel';

const cookies = new Cookies();

class Myaccount extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: "",
      orderStatus: 0,
      loading: false,
      subtotal: 0,
      total: 0,
      orderList: [],
      first_name: "",
      last_name: "",
      phone: "",
      email:"",
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      line1: "",
      line2: "",
      line3: "",
      town: "",
      county: "",
      country: "",
      town:"",
      postcode: "",
      errors: [],
      showDetail: false,
      showedId: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.logout = this.logout.bind(this);

  }

  loadScript() {
    let scripts = [{ src: globalData.apiUrl + "/front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
  
    this.getOrdersData();
    $(".dropdn_caret").removeClass("is-hovered");
    if(localStorage.getItem('token'))
    {
      this.getAuth(localStorage.getItem('token'));
    }
    $(".dropdn-content").removeClass("is-opened");
    // this.handleScroll();
    //Append the script element on each iteration
    this.loadScript();
    
  }

  componentDidUpdate() {

  }


  componentWillUnmount() {
    const script = document.getElementById("myScript");
    // console.log("script 2 ", script);
    script.parentNode.removeChild(script);
  }

  getAuth(token) {
    axios
      .get(globalData.apiUrl + "/api/auth", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        this.setState({
          first_name: result.data.user.first_name,
          last_name: result.data.user.last_name,
          email: result.data.user.email,
          phone: result.data.user.phone,
          line1:  result.data.user.userBillingAddressInfo.line1,
          line2: result.data.user.userBillingAddressInfo.line2,
          line3: result.data.user.userBillingAddressInfo.line3,
          town: result.data.user.userBillingAddressInfo.town,
          county: result.data.user.userBillingAddressInfo.county,
          country: result.data.user.userBillingAddressInfo.country,
          postcode: result.data.user.userBillingAddressInfo.postcode,
        });
      })
      .catch((error) => {
        this.logout();
      });
  }

  handleClick(e, id) {
    if(this.state.orderStatus)
    {
      this.setState({
        orderStatus: 0,
        showedId: "",
      });
    }else{
      this.setState({
        orderStatus: 1,
        showedId: id,
      });
    }
   
  }

  handleChange(event) {
    const { name, value } = event.target;
   
    this.setState({
      [name]: value,
    });
  }

  logout() {

    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((confirmed) => {
      // console.log(confirmed);
      if (confirmed.value) {
        localStorage.removeItem("token");
        this.setState({
          user: "",
        });
        this.props.removeUser();
            // Swal.fire("Got it!", "", "success");
      } else {
        // Swal.fire("You cancel", "", "error");
      }
    });
  
   
  }

  getOrdersData(){

    axios.get(globalData.apiUrl+'/api/orders', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
      }).then(result => {
        // console.log(result.data.data.orderList);
        this.setState({
					orderList: [...result.data.data.orderList],
				})

      }).catch(error => {
  
    })
  }

  handleAddressSubmit(e) {
    // console.log(e);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
    // console.log(this.state);
    if (this.validateAddressForm()) {
      this.setState({  
        loading: true
      });
      axios
        .put(globalData.apiUrl + "/api/user-address-update",
        {
          line1: this.state.line1,
          line2: this.state.line2,
          line3: this.state.line3,
          town: this.state.town,
          county: this.state.county,
          country: this.state.country,
          postcode: this.state.postcode,
        },
        config,
        )
        .then((response) => {
          
          if(response.data.status){
            this.setState({  
              loading: false
            });
            Swal.fire({
              title:response.data.message,
              showConfirmButton: false,
              icon:"success",
              timer: 3000,
              timerProgressBar: true,
            });
              // Swal.fire(response.data.message, "", "success");
            }else{
              Swal.fire(response.data.message, "", "error");
            }
        })
        .catch((err) => {
          this.setState({  
            loading: false
          })
        //  console.log(err);
        });
    }
  }

  handleSubmit(e) {
    // console.log(e);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
    // console.log(this.state);
    if (this.validateForm()) {
      this.setState({  
        loading: true
      });
      axios
        .put(globalData.apiUrl + "/api/user",
        {
          email: this.state.email,
          currentPassword: this.state.currentPassword,
          newPassword: this.state.newPassword,
          confirmNewPassword: this.state.confirmNewPassword,
        },
        config,
        )
        .then((response) => {
          this.setState({
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            loading: false,
          });
          if(response.data.status){
             
              Swal.fire(response.data.message, "", "success");
            }else{
              Swal.fire(response.data.message, "", "error");
            }
        })
        .catch((err) => {
          this.setState({  
            loading: false
          });
        //  console.log(err);
        });
    }
  }

  validateAddressForm() {
   
    let errors = [];
    let formIsValid = true;

    if (!this.state.line1) {
      formIsValid = false;
      errors["line1"] = "*Please enter your line1.";
    }

    if (!this.state.town) {
      formIsValid = false;
      errors["town"] = "*Please enter your town.";
    }
    if (!this.state.county) {
      formIsValid = false;
      errors["county"] = "*Please enter your county.";
    }
    if (!this.state.country) {
      formIsValid = false;
      errors["country"] = "*Please enter your country.";
    }

    if (!this.state.postcode) {
      formIsValid = false;
      errors["postcode"] = "*Please enter your postcode.";
    }
  

    //  console.log(errors);
    this.setState({
      errors: errors,
    });

    return formIsValid;
  }

  validateForm() {
   
    let errors = [];
    let formIsValid = true;

    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof this.state.email !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }

     if (this.state.currentPassword == '') {
      // formIsValid = false;
   
    }else{
     
      // errors["currentPassword"] = "*Please enter your phone.";
        if((!this.state.newPassword)){
          formIsValid = false;
          errors["newPassword"] = "*Please enter your New password.";
        }else if(this.state.newPassword.length < 8){
          formIsValid = false;
          errors["newPassword"] = "*The new password must be at least 8 characters";
        }
        else if(!this.state.confirmNewPassword){
          formIsValid = false;
          errors["confirmNewPassword"] = "*Please enter your Confirm password.";
        }else if(this.state.newPassword != this.state.confirmNewPassword){
          formIsValid = false;
          errors["confirmNewPassword"] = "*Password does not match.";
        }
    }

    //  console.log(formIsValid);
    this.setState({
      errors: errors,
    });

    return formIsValid;
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }

  render() {
    // console.log(this.state);
    // console.log(this.state.cartList);
    const NotShow = false;
    if(localStorage.getItem('token') && NotShow)
    {
      return (
        <div className="page-content">
              { this.state.loading ?
              <Loading />

              : ''
            }
          <div id="my-account" className="bg-dark for-padding-review">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg">
                  <h1 className="text-center text-white">My Account</h1>
                </div>
              </div>
              <ul className="nav nav-tabs d-flex justify-content-between" id="myTab" role="tablist">
                {/* <li className="nav-item">
                  <a className="nav-link" id="dashboard" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                    Dashboard
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link " id="orders-tab" data-toggle="tab" href="#orders" role="tab" aria-controls="orders" aria-selected="true">
                    Orders
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link " id="addresses-tab" data-toggle="tab" href="#addresses" role="tab" aria-controls="addresses" aria-selected="true">
                    Addresses
                  </a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link active" id="account-tab" data-toggle="tab" href="#account" role="tab" aria-controls="account" aria-selected="false">
                    Account
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="logout-tab" onClick={(e) => this.logout(e)} aria-controls="logout" aria-selected="false">
                    Logout
                  </a>
                </li>
              </ul>
              <div className="tab-content text-white" id="myTabContent">
                {/* <div className="tab-pane fade py-4" id="home" role="tabpanel" aria-labelledby="home-tab"> */}
                  {/* Lorem ipsum dolor sit amet, consectetur adipisicing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                  aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. */}
                {/* </div> */}
                <div className="tab-pane fade py-4" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                
                  { this.state.orderStatus ?
                  <React.Fragment>
                    <Order_detail handleClick={this.handleClick} orderId={ this.state.showedId}/>
                    </React.Fragment>
                  :
                  <React.Fragment>
                    <OrderList handleClick={this.handleClick} orderList={this.state.orderList} />
                    </React.Fragment>
                  }
                </div>
                <div className="tab-pane fade py-4" id="addresses" role="tabpanel" aria-labelledby="addresses-tab">
                <h3 className="text-center mt-3 text-white mb-md-5">
                        Your Address
                      </h3>
                <div className="row mt-4">
                    
                      <div className="col-md-6 text-center">
                      <form  id="myaccount">
                        
                          <div className="form-group">
                            <label htmlFor="line1">Line 1 <span className="required text-danger">*</span></label>
                            <input type="text" className="form-control" name="line1" id="line1" onChange={this.handleChange} placeholder="Address Line 1" value={this.state.line1} />
                            {this.state.errors.line1 && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.line1}</strong>
                            </div>
                          )}
                          </div>
                        
                        <div className="form-row">
                          <div className="col-md-6 form-group">
                            <label htmlFor="line_2">Line 2</label>
                            <input type="text" className="form-control"  name="line2" id="line2"  onChange={this.handleChange} placeholder="Address Line 2" value={this.state.line2} />
                          </div>
                          <div className="col-md-6 form-group">
                            <label htmlFor="line_3">Line 3</label>
                            <input type="text" className="form-control" name="line3" id="line3" onChange={this.handleChange} placeholder="Address Line 3" value={this.state.line3} />
                            {this.state.errors.line3 && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                <strong>{this.state.errors.line3}</strong>
                              </div>
                            )}
                                
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="text">Town / City <span className="required text-danger">*</span></label>
                          <input type="text" className="form-control" name="town"  onChange={this.handleChange} placeholder="City" value={this.state.town} id="city" />
                          {this.state.errors.town && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.town}</strong>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                    <div className="col-md-6 text-center">
                     
                      <form>
                        
                        <div className="form-group">
                          <label htmlFor="county">County  <span className="required text-danger">*</span></label>
                          <input type="text" className="form-control" name="county" onChange={this.handleChange} placeholder="County" value={this.state.county} id="county" />
                          {this.state.errors.county && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.county}</strong>
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="zipcode">
                          Postcode / Zip code <span className="required text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" name="postcode" onChange={this.handleChange}  placeholder="Zipcode" value={this.state.postcode} id="zipcode" />
                          {this.state.errors.postcode && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.postcode}</strong>
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="country">
                            Country <span className="required text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" name="country" onChange={this.handleChange} placeholder="Country" value={this.state.country} id="country" />
                          {this.state.errors.country && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.country}</strong>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                    <div className="col-md-12 text-center my-5">
                      <button className="btn btn-success rounded-100" type="button" onClick={(e) => this.handleAddressSubmit(e)}>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                  <div className="row mt-4">
                    <div className="col-md-6 text-center">
                      <h3 className="text-center text-white mb-md-5">
                        Your Account
                      </h3>
                      <form  id="myaccount">
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="firstname">First Name <span className="required text-danger">*</span></label>
                            <input type="text" className="form-control for-email-cursor" name="first_name" id="firstname" onChange={this.handleChange} placeholder="Enter Your First Name" value={this.state.first_name} disabled />
                            {this.state.errors.first_name && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.first_name}</strong>
                            </div>
                          )}
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="lastname">Last Name <span className="required text-danger">*</span></label>
                            <input type="text" className="form-control for-email-cursor"  name="last_name"  id="lastname" onChange={this.handleChange} placeholder="Enter Your Last Name" value={this.state.last_name}  disabled/>
                            {this.state.errors.last_name && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.last_name}</strong>
                            </div>
                          )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input type="email" className="form-control"  name="email" id="email" placeholder="Enter Your Email"  onChange={this.handleChange} value={this.state.email} />
                          {this.state.errors.email && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.email}</strong>
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone">Phone Number <span className="required text-danger">*</span></label>
                          <input type="number" className="form-control for-email-cursor" name="phone" id="phone" onChange={this.handleChange} placeholder="Enter Your Number" value={this.state.phone}  disabled/>
                          {this.state.errors.phone && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.phone}</strong>
                            </div>
                          )}
                              
                        </div>
                      </form>
                    </div>
                    <div className="col-md-6 text-center">
                      <h3 className="text-center text-white mb-md-5">
                        Password Change
                      </h3>
                      <form>
                        <div className="form-group">
                          <label htmlFor="current_password">Current Password  <span className="required text-danger">*</span></label>
                          <input type="password" className="form-control" name="currentPassword"  onChange={this.handleChange} value={this.state.currentPassword} id="current_password" />
                          {this.state.errors.currentPassword && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.currentPassword}</strong>
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="new_password">New Password  <span className="required text-danger">*</span></label>
                          <input type="password" className="form-control" name="newPassword" onChange={this.handleChange} value={this.state.newPassword} id="new_password" />
                          {this.state.errors.newPassword && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.newPassword}</strong>
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="confirm_password">
                            Confirm New Password  <span className="required text-danger">*</span>
                          </label>
                          <input type="password" className="form-control" name="confirmNewPassword" onChange={this.handleChange} value={this.state.confirmNewPassword} id="confirm_password" />
                          {this.state.errors.confirmNewPassword && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              <strong>{this.state.errors.confirmNewPassword}</strong>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                    <div className="col-md-12 text-center my-5">
                      <button className="btn btn-success rounded-100" type="button" onClick={(e) => this.handleSubmit(e)}>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="tab-pane fade pb-4" id="logout" role="tabpanel" aria-labelledby="logout-tab">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                  aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </div> */}
              </div>
            </div>
          </div>
        </div>
      );
    }else{
      return(
        <div className="page-content">
        { this.state.loading ?
              <Loading />

              : ''
            }
          <div id="my-account" className="bg-dark for-padding-review">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg">
                  <h1 className="text-center text-white">Coming Soon</h1>
                </div>
              </div>
            </div>
          </div>
          </div>
            
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    cartCount: state.cart_count,
    userData: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
    removeUser: () => dispatch({ type: "USER", value: "guest" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Myaccount);
