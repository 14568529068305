import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import Authentfication from "./auth/Authentification";
import Submenu from "./includes/Submenu";
import SubHeader from "./Subheader";
import axios from "axios";
import $ from "jquery";
import Submenu_mobile from "./includes/Submenu_mobile";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import globalData from "./config.json";

const cookies = new Cookies();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      subtotal: 0,
      total: 0,
      cartList: [],
      // event: [],
      countCartItemsQuantity: 0,
      cartItemCount: 0,
      wishlistCount: 0,
    };
    // this.handleScroll = this.handleScroll.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.applyCss = this.applyCss.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    // console.log(" in header ");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // console.log(" in header");
    // this.getEvents()
    // debugger;
    // console.log(cookies.get("guesttoken"));
    if (
      cookies.get("guesttoken") == undefined &&
      cookies.get("guesttoken") != "" &&
      localStorage.getItem("token") == undefined
    ) {
      //   console.log(cookies.get("guesttoken"));

      this.getguestAuth();
    } else {
      //this.getGuestShoppingCartList()
      //this.getGuestShoppingCartCount()
      if (localStorage.getItem("token")) {
        // console.log("IN IFIF" );
        this.getShoppingCartCount();
      } else {
        this.getGuestShoppingCartCount();
      }
      // window.addEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
    this.setState({
      loading: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.cartCount );
    if (this.props.cart_item_count !== this.state.countCartItemsQuantity) {
      if (localStorage.getItem("token")) {
        // console.log("IN IFIF" );
        this.getShoppingCartCount();
      } else {
        this.getGuestShoppingCartCount();
      }
    }

    /* const script = document.getElementById("myScript");
    if (script == undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } */

    /*  window.addEventListener('scroll', this.isSticky);
    return () => {
        window.removeEventListener('scroll', this.isSticky);
    }; */
  }

  getShoppingCartCount() {
    // var RandomNumber = Math.floor(Math.random() * 100) + 1 ;
    // console.log("getShoppingCartCount RandomNumber "+RandomNumber)
    // this.setState({cartItemCount: RandomNumber})
    // this.props.updateCartCount(RandomNumber);
    //this.setState({cartItemCount: this.state.cartList.length})
    //this.props.updateCartCount(this.state.cartList.length)

    axios
      .get(globalData.apiUrl + `/api/cart`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        // console.log("====== SCC =====");
        let cartData = response.data.data;
        // console.log(cartData);
        let cartCountTemp = 0;
        if (
          typeof cartData.cartList !== "undefined" &&
          cartData.cartList.length > 0
        ) {
          cartCountTemp = cartData.cartList.length;

          this.setState({
            cartList: [...response.data.data.cartList],
            subtotal: response.data.data.total,
            total: response.data.data.totalPayableAmount,
            loading: false,
          });
          this.setState({
            cartItemCount: cartCountTemp,
            countCartItemsQuantity: response.data.data.countCartItemsQuantity,
          });
          this.props.updateCartCount(cartCountTemp);
          this.props.updateCartQuantity(
            response.data.data.countCartItemsQuantity
          );
          // this.handleScroll();
        } else {
          this.setState({
            cartList: [],
            subtotal: 0,
            total: 0,
            loading: false,
          });
          this.setState({ cartItemCount: 0, countCartItemsQuantity: 0 });
          this.props.updateCartCount(this.state.cartItemCount);
          this.props.updateCartQuantity(this.state.countCartItemsQuantity);

          // this.handleScroll();
        }
        //this.props.updateCartCount(cartCountTemp);
        localStorage.setItem("cart_count", cartCountTemp);
      })
      .catch(function (error) {
        // console.log(error)
      });
  }

  getGuestShoppingCartCount() {
    // var RandomNumber = Math.floor(Math.random() * 100) + 1;
    // console.log("getGuestShoppingCartCount RandomNumber "+RandomNumber)
    /* this.setState({cartItemCount: RandomNumber})
        this.props.updateCartCount(RandomNumber); */
    //this.setState({cartItemCount: this.state.cartList.length})
    //this.props.updateCartCount(this.state.cartList.length)
    // console.log('dddddddddddddddddddddddddddddddd');

    axios
      .get(
        globalData.apiUrl + `/api/cart?paramId=${cookies.get("guesttoken")}`,
        {}
      )
      .then((response) => {
        let cartData = response.data.data;
        let cartCountTemp = 0;
        if (
          typeof cartData.cartList !== "undefined" &&
          cartData.cartList.length > 0
        ) {
          cartCountTemp = cartData.cartList.length;

          this.setState({
            cartList: [...response.data.data.cartList],
            subtotal: response.data.data.total,
            total: response.data.data.totalPayableAmount,
            loading: false,
          });
          this.setState({
            cartItemCount: cartCountTemp,
            countCartItemsQuantity: response.data.data.countCartItemsQuantity,
          });
          this.props.updateCartCount(cartCountTemp);
          this.props.updateCartQuantity(
            response.data.data.countCartItemsQuantity
          );

          // this.handleScroll();
        } else {
          this.setState({
            cartList: [],
            subtotal: 0,
            total: 0,
            loading: false,
          });
          this.setState({ cartItemCount: 0, countCartItemsQuantity: 0 });
          this.props.updateCartCount(this.state.cartItemCount);
          this.props.updateCartQuantity(this.state.countCartItemsQuantity);

          // this.handleScroll();
        }
        //this.props.updateCartCount(cartCountTemp);
        localStorage.setItem("cart_count", cartCountTemp);
      })
      .catch(function (error) {
        // console.log(error)
      });
  }

  getguestAuth() {
    // console.log('hhhhhhhhhhhhhhhhh', 'heder guest auth');
    // return false;

    axios
      .get(globalData.apiUrl + "/api/cart", {})
      .then((result) => {
        // console.log(result);
      })
      .catch((error) => {
        cookies.set("guesttoken", error.response.data.generatedUserId, {
          path: "/",
        });

        if (localStorage.getItem("token")) this.getShoppingCartList();
        else if (cookies.get("guesttoken")) {
          this.getGuestShoppingCartList();
        }
        // console.log(error.response.data.generatedUserId);
        // console.log(error.response.status);
      });
  }

  removeCart(id, event) {
    Swal.fire({
      title: "Are you sure?",
      // text: "You want To remove ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((confirmed) => {
      if (confirmed.value) {
        if (localStorage.getItem("token")) {
          axios
            .delete(globalData.apiUrl + `/api/cart/${id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((response) => {
              this.getShoppingCartCount();

              Swal.fire({
                title: response.data.message,
                showConfirmButton: false,
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              });
              // this.handleScroll();
            })
            .catch(function (error) {
              // console.log(error)
            });
        } else {
          axios
            .delete(
              globalData.apiUrl +
                `/api/cart/${id}?paramId=${cookies.get("guesttoken")}`,
              {}
            )
            .then((response) => {
              this.getGuestShoppingCartCount();

              Swal.fire({
                title: response.data.message,
                showConfirmButton: false,
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              });
              // this.handleScroll();
            })
            .catch(function (error) {
              // console.log(error)
            });
        }
      } else {
        // Swal.fire({
        //   title:"You cancel",
        //   showConfirmButton: false,
        //   icon:"error",
        //   timer: 3000,
        //   timerProgressBar: true,
        // });
        // Swal.fire("You cancel", "", "error");
      }
    });
  }

  getShoppingCartList() {
    this.setState({ loading: true });

    axios
      .get(globalData.apiUrl + `/api/cart?page=`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        // console.log("====== GSCC =====");
        let cartData = response.data.data;
        //  console.log(cartData);
        // console.log(response.data.data);
        let cartCountTemp = 0;
        this.setState({
          cartList: [...response.data.data.cartList],
          subtotal: response.data.data.total,
          total: response.data.data.totalPayableAmount,
          loading: false,
        });
        this.setState({
          cartItemCount: cartCountTemp,
          countCartItemsQuantity: response.data.data.countCartItemsQuantity,
        });
        this.props.updateCartCount(this.state.cartItemCount);
        this.props.updateCartQuantity(
          response.data.data.countCartItemsQuantity
        );
      })
      .catch(function (error) {
        // console.log(error)
      });
  }

  getGuestShoppingCartList() {
    this.setState({ loading: true });

    axios
      .get(
        globalData.apiUrl +
          `/api/cart?paramId=${cookies.get("guesttoken")}&page=`,
        {}
      )
      .then((response) => {
        if (
          typeof response.data.data.cartList !== "undefined" &&
          response.data.data.cartList.length > 0
        ) {
          let cartCountTemp = 0;
          this.setState({
            cartList: [...response.data.data.cartList],
            subtotal: response.data.data.total,
            total: response.data.data.totalPayableAmount,
            loading: false,
          });
          this.setState({
            cartItemCount: cartCountTemp,
            countCartItemsQuantity: response.data.data.countCartItemsQuantity,
          });
          this.props.updateCartCount(this.state.cartItemCount);
          this.props.updateCartQuantity(
            response.data.data.countCartItemsQuantity
          );
        } else {
          this.setState({
            cartList: [],
            subtotal: 0,
            total: 0,
            loading: false,
          });
          this.setState({ cartItemCount: 0, countCartItemsQuantity: 0 });
          this.props.updateCartCount(this.state.cartItemCount);
          this.props.updateCartQuantity(this.state.countCartItemsQuantity);
        }

        // localStorage.setItem('cart_count', this.state.cartList.length);
      })
      .catch(function (error) {
        // console.log(error)
      });

    // axios.get(globalData.apiUrl+`/api/cart?paramId=${cookies.get('guesttoken')}`, {

    // }).then(( response ) => {
    //     console.log("====== GSCL =====");
    // 	console.log(response.data.data);
    //     this.setState({
    // 		cartList: [...response.data.data.cartList],
    // 		subtotal: response.data.data.total,
    // 		total: response.data.data.totalPayableAmount,
    // 		loading: false
    // 	})
    //     this.props.updateCartCount(this.state.cartList.length);
    //     localStorage.setItem('cart_count', this.state.cartList.length)

    // }).catch(function (error) {
    //     console.log(error)
    // })
  }

  closeMegaMenu() {
    // console.log(" closeMegaMenu ");
    $(".mmenu-item--mega.hovered").removeClass("hovered");
  }

  clone() {
    return React.cloneElement(<SubHeader />);
  }

  closeMobileMenu() {
    // console.log("close closeMobileMenu");
    $(".mobilemenu").toggleClass("active");
    $(".mobilemenu-toggle").toggleClass("active");
    $("body").toggleClass("slidemenu-open");
    /* const script = document.getElementById("myScript");
    console.log(" SUb MENU SCRIPT", script);
    if (script) {
      // The script element exists, so it's safe to remove it
      //script.parentNode.removeChild(script);
      script.parentNode = null;
    } else {
      // The script element with the specified ID was not found
      console.log("Script element not found.");
    } */
  }

  openMobileMenu(e) {
    // console.log(" openMobileMenu 1");
    //e.preventDefault();
    $(".mobilemenu").toggleClass("active");
    $(".mobilemenu-toggle").toggleClass("active");
    $("body").toggleClass("slidemenu-open");
   // alert("in");
  }
  applyCss(){
    $("body").css("overflow",'scroll !important');
    $("body").addClass("dev-bb");
  }


  handleLinkClick = (e) => {
    // console.log("close");
    $('.js-dropdn-close').trigger('click');
    //  alert("in handleLinkClick");

   
  };
  render() {
    const mystyle = {
      backgroundColor: "#00a0df",
      padding: "20px",
    };

    const rowstyle = {
      display: "flex",
      justifyContent: "space-between",
    };
    // console.log(this.props.cartCount, 'render' );
    // console.log(this.state.cartList.length);

    // if (window.location.hash === '#/user-login' || window.location.hash === '#/user-register' || window.location.hash === '#/forgot-password' || window.location.href.indexOf("#/reset-password") > -1) return null;

    

    return (
      <header className="hdr-wrap">
        <div className="hdr-content hdr-content-sticky">
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="show-mobile">
                <div className="menu-toggle">
                  <a
                    href="#"
                    className="mobilemenu-toggle-menu-customize"
                    onClick={this.openMobileMenu}
                  >
                    <i className="icon-menu"></i>
                  </a>
                </div>
              </div>
              <div className="hdr-logo show-mobile">
                <Link to={"/"} className="logo">
                  {" "}
                  <img fetchpriority="high"
                    src={`${globalData.baseUrl}front/images/logo-2.png`}
                    alt="Logo"
                  />{" "}
                </Link>
              </div>

              <div className=" show-mobile">
                <div className="menu-toggle">
                  <a
                    href="#"
                    className=" mt-1 dropdn-link js-dropdn-link minicart-link"
                    data-panel="#dropdnMinicart"
                  >
                    <i className="icon-basket"></i>{" "}
                    <span className="minicart-qty iiiiii">
                      {this.props.cartCount > 0 ? this.props.cartCount : "0"}
                    </span>
                  </a>
                </div>
              </div>

              <div className="hdr-nav hide-mobile nav-holder-s1">
                {this.clone()}
              </div>
            </div>
          </div>
        </div>
        <div className="hdr">
          <div className="hdr-content">
            <div
              className="logo-top-header"
              style={{ background: "#000", padding: "20px 0px" }}
            >
              <div className="container">
                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="col-auto show-mobile">
                    <div className="menu-toggle">
                      <a href="#" className="mobilemenu-toggle mmmmmmm"  onClick={this.openMobileMenu}>
                        <i className="icon-menu" />
                      </a>
                    </div>
                  </div>
                  <div className="col-auto hdr-logo">
                    <Link to={"/"} className="logo">
                      <img fetchpriority="high"
                        src={`${globalData.baseUrl}front/images/twynhams-tours-logo-dark.png`}
                        alt="Logo"
                        style={{ width: 230, height: "auto" }}
                      />
                    </Link>
                  </div>
                  <div className="col-auto hdr-logo hide-mobile">
                    <div className="call-experts text-white text-right">
                      To speak to one of our experts call
                      <br />
                      UK <i className="fa fa-phone" />{" "}
                      <a className="text-white" href="tel:03333034442">
                      07739 944 339
                      </a>
                      <br />
                      INT'L <i className="fa fa-phone" />{" "}
                      <a className="text-white" href="tel:+443333034442">
                      +44 7739 944 339 
                      </a>
                    </div>
                  </div>
                  <div className="col-auto show-mobile">
                    <div className="menu-toggle">
                      <a
                        href="#"
                        className="dropdn-link js-dropdn-link minicart-link"
                        data-panel="#dropdnMinicart"
                      >
                        <i className="icon-basket" />
                        <span className="minicart-qty">
                          {" "}
                          {this.props.cartCount > 0
                            ? this.props.cartCount
                            : "0"}
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-auto d-none">
                    <div className="menu-toggle">
                      <a
                        href="#"
                        className="dropdn-link js-dropdn-link minicart-link"
                        data-panel="#"
                      >
                        <i className="icon-user" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="nav-bottom-header"
              style={{ background: "#00a0df" }}
            >
              <div className="container">
                <div className="row">
                  <div className="hdr-nav hide-mobile nav-holder justify-content-center">
                    <SubHeader />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-side-panel">
          <div className="mobilemenu js-push-mbmenu">
            <div className="mobilemenu-content">
              <div
                className="mobilemenu-close mobilemenu-toggle-close-menu-customize"
                onClick={this.closeMobileMenu}
              >
                Close
              </div>
              <div className="mobilemenu-scroll">
                <div className="mobilemenu-search"></div>
                <div className="nav-wrapper show-menu">
                  <div className="nav-toggle">
                    <span className="nav-back">
                      <i className="icon-angle-left"></i>
                    </span>{" "}
                    <span className="nav-title"></span>
                  </div>
                  <ul className="nav nav-level-1">
                    <li>
                      <NavLink
                        to="/"
                        activeclassname="active"
                        onClick={this.closeMobileMenu}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <a>
                        Tours
                        <span className="arrow">
                          <i className="icon-angle-right"></i>
                        </span>
                      </a>
                      <Submenu_mobile key={1115} />
                    </li>
                    <li>
                      <NavLink
                        to="/about-us"
                        activeclassname="active"
                        onClick={this.closeMobileMenu}
                      >
                        About Us
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/our-fleet" activeclassname="active">
                        Our Fleet 
                      </NavLink>
                    </li> */}
                    <li className="mmenu-item--mega">
                      <NavLink
                        to="/reviews"
                        activeclassname="active"
                        onClick={this.closeMobileMenu}
                      >
                        Reviews
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contact-us"
                        activeclassname="active"
                        onClick={this.closeMobileMenu}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <li>
                      <a
                        rel="nofollow"
                        title="Mailing List"
                        href="https://mailchi.mp/twynhamstours/mailing_list"
                        target="_blank"
                      >
                        Mailing List
                      </a>
                    </li>
                    <Authentfication />
                    <li>
                      <a
                        className="dropdn-link js-dropdn-link minicart-link my-1 hide-mobile"
                        data-panel="#dropdnMinicart"
                        onClick={this.applyCss}
                      >
                        View Basket
                        <span>({this.props.cartCount})</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mobilemenu-bottom">
                  <div className="mobilemenu-language"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="dropdn-content account-drop" id="dropdnAccount">
            <div className="dropdn-content-block">
              <div className="dropdn-close">
                <span className="js-dropdn-close">Close</span>
              </div>
              <ul>
                <li>
                  <a href="#">
                    <span>Log In</span>
                    <i className="icon-login"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span>Register</span>
                    <i className="icon-user2"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span>Checkout</span>
                    <i className="icon-card"></i>
                  </a>
                </li>
              </ul>
              <div className="dropdn-form-wrapper">
                <h5>Quick Login</h5>
                <form action="#">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control--sm is-invalid"
                      placeholder="Enter your e-mail"
                    />
                    <div className="invalid-feedback">Can't be blank</div>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control--sm"
                      placeholder="Enter your password"
                    />
                  </div>
                  <button type="submit" className="btn">
                    Enter
                  </button>
                </form>
              </div>
            </div>
            <div className="drop-overlay js-dropdn-close"></div>
          </div>
          <div className="dropdn-content minicart-drop" id="dropdnMinicart">
            <div className="dropdn-content-block">
              <div className="dropdn-close">
                <span className="js-dropdn-close">Close</span>
              </div>
              {this.state.cartList && this.state.cartList.length > 0 ? (
                <div className="minicart-drop-content js-dropdn-content-scroll">
                  {this.state.cartList.map((ct, index) => {
                    return (
                      <div className="minicart-prd row" key={ct.id}>
                        <div className="minicart-prd-info col">
                          <div className="minicart-prd-tag">
                            Package Description
                          </div>
                          <h2 className="minicart-prd-name">
                            <a href="#">{ct.packageName}</a>
                          </h2>
                          {ct.meta.is_installments == 1 ? (
                            <div className="minicart-prd-tag">
                              {" "}
                              <a href="#">Instalment</a>
                              <div className="product-info">
                                <div>
                                  {ReactHtmlParser(
                                    ct.installmentsData.installmentsMessage
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="minicart-prd-qty row">
                            <div className="col-md-3">
                              <span className="minicart-prd-qty-label">
                                Quantity:
                              </span>
                              <span className="minicart-prd-qty-value">
                                {ct.quantity}
                              </span>
                            </div>
                            <div className="col-md-9">
                              <div className="minicart-prd-price prd-price">
                                <div className="price-new">
                                  £{ct.unit_price}
                                </div>
                              </div>
                            </div>
                          </div>
                          {ct.supplements_status == 1 &&
                          ct.supplements_total_number > 0 ? (
                            <div className="product-name mt-2">
                              {" "}
                              <div className="minicart-prd-tag">
                                Supplements
                              </div>
                              <div className="product-info">
                                <div>
                                  {
                                    ct.cartItemSupplement.supplementsData
                                      .supplements_p1
                                  }
                                  &nbsp;&nbsp;{" "}
                                  {
                                    ct.cartItemSupplement.supplementsData
                                      .supplements_p2
                                  }
                                  &nbsp;
                                  {
                                    ct.cartItemSupplement.supplementsData
                                      .supplements_p3
                                  }
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {ct.supplements_status == 1 &&
                          ct.cartItemSupplement !== undefined &&
                          ct.supplements_total_number > 0 ? (
                            <div className="minicart-prd-tag">
                              {ct.cartItemSupplement.supplementsData
                                .installmentsData !== undefined ? (
                                <>
                                  <a href="#">Instalment</a>
                                  <div className="product-info">
                                    <div>
                                      {ReactHtmlParser(
                                        ct.cartItemSupplement.supplementsData
                                          .installmentsData.installmentsMessage
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {ct.cartItemSupplement &&
                          ct.supplements_total_number > 0 ? (
                            <div className="minicart-prd-qty row">
                              <div className="col-md-3">
                                <span className="minicart-prd-qty-label">
                                  Quantity:
                                </span>
                                <span className="minicart-prd-qty-value">
                                  {ct.cartItemSupplement.quantity}
                                </span>
                              </div>
                              <div className="col-md-9">
                                <div className="minicart-prd-price prd-price">
                                  <div className="price-new">
                                    £{ct.cartItemSupplement.unit_price}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="minicart-prd-action">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={(e) => this.removeCart(ct.idEncrypt, e)}
                            className="product-remove"
                            data-line-number="1"
                          >
                            <i className="icon-recycle"></i>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>
                  <div className="minicart-empty js-minicart-empty ">
                    <div className="minicart-empty-text">
                      Your shopping basket is empty
                    </div>
                    {/* <div className="minicart-empty-icon">
                      <i className="icon-shopping-bag" />
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 306 262"
                        style={{ enableBackground: "new 0 0 306 262" }}
                        xmlSpace="preserve"
                      >
                        <path
                          className="st0"
                          d="M78.1,59.5c0,0-37.3,22-26.7,85s59.7,237,142.7,283s193,56,313-84s21-206-69-240s-249.4-67-309-60C94.6,47.6,78.1,59.5,78.1,59.5z"
                        />
                      </svg>
                    </div> */}
                  </div>
                  <div className="minicart-drop-info d-md-block">
                    <div className="shop-feature-single row no-gutters align-items-center">
                      <div className="shop-feature-icon col-auto">
                        <i className="icon-truck" />
                      </div>
                      {/* <div className="shop-feature-text col"><b>SHIPPING!</b> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</div> */}
                    </div>
                  </div>
                </div>
              )}
              {this.state.cartList && this.state.cartList.length > 0 ? (
                <div className="minicart-drop-fixed js-hide-empty">
                  <div
                    className="loader-horizontal-sm js-loader-horizontal-sm"
                    data-loader-horizontal=""
                  >
                    <span></span>
                  </div>
                  <div className="minicart-drop-total js-minicart-drop-total row no-gutters align-items-center">
                    <div className="minicart-drop-total-txt col-auto heading-font">
                      Total Tour Price
                    </div>
                    <div
                      className="minicart-drop-total-price col"
                      data-header-cart-total=""
                    >
                      £{this.state.subtotal}
                    </div>
                  </div>
                  <div className="minicart-drop-actions">
                    <Link
                    onClick={this.handleLinkClick}
                      to="/checkout"

                      className="btn btn--md font-weight-bold js-dropdn-close1"
                    >
                      <i className="icon-basket font-weight-bold"></i>
                      <span>View Basket</span>
                    </Link>{" "}
                    {/* <Link to="/checkout" className="btn btn--md">
                      <i className="icon-checkout"></i>
                      <span>Check out</span>
                    </Link> */}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="drop-overlay js-dropdn-close"></div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartCount: state.cart_count,
    cart_item_count: state.cart_item_count,
    userData: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
    updateCartQuantity: (quantity) =>
      dispatch({ type: "CART_ITEM_QUANTITY", value: quantity }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
