import React, { Component } from "react";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import globalData from "../config.json";
import $ from "jquery";
class Authentification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      redirect: "",
    };

    this.handleClick = this.handleClick.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (localStorage.getItem("token")){
      this.getAuth(localStorage.getItem("token"));
    }
  }

  componentDidUpdate() {
    if (this.props.user)
      if (this.props.user.name != this.state.user.name)
        this.setState({ user: this.props.user });
  }

  getAuth(token) {
    axios
      .get(globalData.apiUrl + "/api/auth", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        this.setState({
          user: result.data.user,
        });
      })
      .catch((error) => {
        this.logout();
      });
  }

  logout() {
 
    localStorage.removeItem("token");
    this.setState({
      user: "",
    });
    this.props.removeUser();
  }


  handleClick(e) {
  
    if (e.target.id == 3) { 
      Swal.fire({
        title: "Are you sure?",
        // text: "U want Logout ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((confirmed) => {
        // console.log(confirmed);
        if (confirmed.value) {
          this.logout();
          // Swal.fire("Got it!", "", "success");
        } else {
          // Swal.fire("You cancel", "", "error");
        }
      });
    }

    // console.log(e.target.id);
    switch (e.target.id) {
      case "0":
        window.location.href = "/dashboard";
        break;
      case "1":
        this.setState({ redirect: "my-account" });
        break;
      case "2":
        this.setState({ redirect: "track-my-order" });
        break;
    }
  }

  closeMobileMenu() {
    // console.log("close");
    // $('.mobilemenu').toggleClass('active');
    // $('.mobilemenu-toggle').toggleClass('active');
    // $('body').toggleClass('slidemenu-open');

    $(".mobilemenu").toggleClass("active");
    $(".mobilemenu-toggle").toggleClass("active");
    $("body").toggleClass("slidemenu-open");
    //$(".nav-level-1").addClass("stay-level-1");
    // $(".nav-level-1").css("transform", "translateX(0%)");
    $(".mmenu-submenu-active")
      .removeClass("mmenu-submenu-active")
      .removeClass("mmenu-submenu-open");
    $(".back-visible").removeClass("back-visible");
   
  }

  render() {
    const mystyle = {
      fontSize: "18px",
      marginTop: "6px",
    };
    const Login = false
    const redirect = this.state.redirect; 
    // hello
    if (redirect) {
      return <Link to={`/${redirect}`} />;
    }

    return this.props.user != "guest" && localStorage.getItem("token") && Login ? ( 
      <li>
        <div className="dropdn_currency">
          <div className="dropdn dropdn_caret">
            <a href="#" className="dropdn-link js-dropdn-link drop-css">
              <i className="icon-user mr-0"></i>
              <i className="icon-angle-down" style={mystyle}></i>
            </a>
            <div className="dropdn-content">
            
              <ul>
                <React.Fragment>
                  <li ><i className="icon-login" /> <a className="active" id={1} data-toggle="tooltip" data-placement="right" data-title="Coming Soon"><span> My Account</span></a></li>
                  <li><i className="icon-user2" /><a onClick={this.handleClick} className="logout-button" id="3" href="#!"><span id="3" >Logout</span></a></li>
                </React.Fragment>
              </ul>

                                   
              {/* <ul>
                <li>
                  <NavLink activeclassname="active" to={"/my-account"} id="1">
                    My Account
                  </NavLink>
                </li>
                <li>
                  <a onClick={this.handleClick} id="3" href="#">
                    Logout
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </li>
    ) : (
      <React.Fragment>
        <li>
          <NavLink activeclassname="active" className="hide-mobile d-none" id="loginButton" to="/login" onClick={this.closeMobileMenu}> 
           Account Login 
          </NavLink> 
        </li> 
        
      </React.Fragment> 
    );
  }
}

const mapStateToProps = (state) => { 
  return {
    user: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeUser: () => dispatch({ type: "USER", value: "guest" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentification);
