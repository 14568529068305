import React from 'react';
import { useState, useEffect, useRef } from "react";
import StepOne from "../Forms/StepOne";
import StepTwo from "../Forms/StepTwo";
import Final from "../Forms/Final";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import globalData from "../config.json";
import Swal from "sweetalert2";
import axios from "axios";
import $ from 'jquery';
// import Loading from "./Loading";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';




function Checkout(props) {

  // console.log(props);
  //state for steps
  const [step, setstep] = useState(1);
  const [user, setuser] = useState("");
  const [cartList, setcartList] = useState([]);
  const [subtotal, setsubtotal] = useState("");
  const [isInstallmentValue, setisInstallmentValue] = useState("");
  const [isSingleRoomsPackagesSoldout, setisSingleRoomsPackagesSoldout] = useState(0);
  const renderAfterCalled = useRef(false);
  const OnlyFirstTimeShow = useRef(true);
  const paymentHiddenRef = useRef();
  

  



  const [total, settotal] = useState("");
  const [errors, setErrors] = useState([]);
  const [ip, setip] = useState("");

  const cookies = new Cookies();
  //state for form data
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    c_email: "",
    password:"",
    c_password:"",
    phone: "",
    line1: "",
    line2: "",
    line3: "",
    city: "",
    County: "",
    zipcode: "",
    Country: "",
    is_guest: true,
    agree: false,
    
  });

  const gotoprevious = (e) => {
    let geturl = cartList.slice(-1).pop();
    window.location.href = globalData.redirectUrl + geturl.meta.slug_event_type;
  };
  
  useEffect(() => {
  
    // console.log(props);
    // document.body.scrollTop = 0;    
    window.scrollTo(0, 0);
    getData();
    loadScript();
    $("#dropdnMinicart").removeClass("is-opened");
    $("body").css("overflow",'scroll !important');
    if (localStorage.getItem("token")) 
      getAuth(localStorage.getItem("token"));
    else if (cookies.get("guesttoken") != "") {
      getGuestShoppingCartList(true);
    }
    document.title = 'Checkout';
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);

    /* let scripts = [{ src: globalData.apiUrl + "/front/js/app-html.js" }];

    $("#dropdnMinicart").removeClass("is-opened");
    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;
      script.async = true;
      document.body.appendChild(script);
    }); */
    // window.addEventListener("scroll", handleScroll());
  }, [props]);


  const loadScript = () => {
    // console.log('hhhhhhhh');
  
      window.scrollTo(0, 0);
      const script = document.getElementById("myScript");
      if (script == null) {
      let scripts = [{ src: globalData.appUrl + "front/js/app-html.js" }];
  
      //Append the script element on each iteration
  
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
  
        document.body.appendChild(script);
      });
    }

   

      // console.log(script);
  

    
  }

  function getAuth(token) {
    axios
      .get(globalData.apiUrl + "/api/auth", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result.data.user);
        setuser(result.data.user);
        setFormData({
          title:  result.data.user.title,
          firstName:  result.data.user.first_name,
          lastName:  result.data.user.last_name,
          email:  result.data.user.email,
          c_email:  result.data.user.email,
          phone:  result.data.user.phone,
          line1: result.data.user.userBillingAddressInfo.line1,
          line2: result.data.user.userBillingAddressInfo.line2,
          line3:result.data.user.userBillingAddressInfo.line3,
          city: result.data.user.userBillingAddressInfo.town,
          County: result.data.user.userBillingAddressInfo.county,
          zipcode: result.data.user.userBillingAddressInfo.postcode,
          Country: result.data.user.userBillingAddressInfo.country,
        });
        getShoppingCartList(true);
      })
      .catch((error) => {
        // console.log(error)
        if (localStorage.getItem("cartList") !== null)
          getGuestShoppingCartList(localStorage.getItem("cartList"));
      });
  }

  function getShoppingCartList(isSupplementSoldout = null) {
    // this.setState({loading: true})

    axios
      .get(globalData.apiUrl + `/api/cart?page=checkout`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        if (
          typeof response.data.data.cartList !== "undefined" &&
          response.data.data.cartList.length > 0
        ) {
          setcartList([...response.data.data.cartList]);
          setisInstallmentValue(response.data.data.includesInstallment);
          setsubtotal(response.data.data.total);
          settotal(response.data.data.totalPayableAmount);
          setisSingleRoomsPackagesSoldout(response.data.data.isSingleRoomsPackagesSoldout);
          props.updateCartCount(response.data.data.countCartItems);
          
          props.updateCartQuantity(response.data.data.countCartItemsQuantity);
          if(response.data.data.isSingleRoomsPackagesSoldout === 1)
          {
            renderAfterCalled.current = true;
          }
          if(response.data.data.isSingleRoomsPackagesSoldout === 1 && renderAfterCalled.current && isSupplementSoldout && OnlyFirstTimeShow.current)
          {
            Swal.fire({
              title:"Our single room packages for this tour have now sold out, You will need to book a minimum of two packages to proceed.",
              showConfirmButton: true,
            icon:"success",
            });
            OnlyFirstTimeShow.current = false;
           
          }
          

        } else {
          window.location.href = globalData.redirectUrl;
        }
      })
      .catch(function (error) {
        // console.log(error)
      });
  }

  function getGuestShoppingCartList(isSupplementSoldout = null) {
    $("body").addClass("dev-bb");
    $("body").removeAttr("style");
    $(".dev-bb").removeAttr("style");
    // console.log("======start getGuestShoppingCartList ======");
    axios
      .get(
        globalData.apiUrl + `/api/cart?paramId=${cookies.get("guesttoken")}&page=checkout`,
        {}
      )
      .then((response) => {
        if (
          typeof response.data.data.cartList !== "undefined" &&
          response.data.data.cartList.length > 0
        ) {
          setcartList([...response.data.data.cartList]);
          setisInstallmentValue(response.data.data.includesInstallment);
          setsubtotal(response.data.data.total);
          settotal(response.data.data.totalPayableAmount);
          setisSingleRoomsPackagesSoldout(response.data.data.isSingleRoomsPackagesSoldout);
          props.updateCartCount(response.data.data.countCartItems);
          // console.log(response.data.data.countCartItems, "xx");
          props.updateCartQuantity(response.data.data.countCartItemsQuantity);
          if(response.data.data.isSingleRoomsPackagesSoldout == 1)
          {
            renderAfterCalled.current = true;
            OnlyFirstTimeShow.current = true;
          }
        //  console.log(window.performance.navigation.type, "ssss");
          if(renderAfterCalled.current && isSupplementSoldout && OnlyFirstTimeShow.current && window.performance.navigation.type !== 1)
          {
           
            props.hidesweetalert(response.data.data.isSingleRoomsPackagesSoldout);
            // console.log(response.data.data.countCartItems, "xssssssssssssssssssssssssssssssssssssssssssx");
            Swal.fire({
              title:"Our single room packages for this tour have now sold out, you will need to book a minimum of two packages to proceed.",
              showConfirmButton: true,
              icon:"success"
            });
            // console.log( this.props, "xxxxxxxxxs");
           
            OnlyFirstTimeShow.current = false;

          }
         
         
         
         
        } else {
          window.location.href = globalData.redirectUrl;
        }
      })
      .catch(function (error) {
        // console.log(error)
      });
  }
  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  const firstStep = () => {
    setstep(step - 2);
  };


  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {

      //  console.log( e.target.name );
    // input value from the form
    let { name, value } = e.target;
    if(e.target.name == "agree")
    {
      //  name = e.target.name;
       value = e.target.checked;
    }
  
    if(e.target.name == "is_guest")
    {
      //  name = e.target.name;
       value = e.target.checked;
    }
    
    // console.log( value );
    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const Formerrors = [];

  const handleChange = (id, event) => {
    const { name, value } = event.target;
    setTimeout(() => {
    

      if (
        cookies.get("guesttoken") !== undefined &&
        localStorage.getItem("token") === undefined ||  localStorage.getItem("token") === null &&
        value !== ""
      ) {
        axios
          .put(globalData.apiUrl + `/api/cart`, {
            id: id,
            quantity: value,
            paramId: cookies.get("guesttoken"),
          })
          .then((response) => {
            
            //  console.log(response.data.length === undefined, "hhhhhhhhhhh");
             if ( typeof response.data.data.cartList !== "undefined" && response.data.data.cartList.length > 0 )
            {
              // console.log(response.data);
              setcartList([...response.data.data.cartList]);
              setsubtotal(response.data.data.total);
              settotal(response.data.data.totalPayableAmount);
              setisSingleRoomsPackagesSoldout(response.data.data.isSingleRoomsPackagesSoldout);
              Swal.fire({
                title:response.data.message,
                showConfirmButton: false,
                icon:"success",
                timer: 3000,
                timerProgressBar: true,
              });
              setstep(step + 1);
              setstep(step);
              // Swal.fire(response.data.message, "", "success");
            }else if(response.data.length === undefined){
  
              
              Swal.fire(response.data.message, "", "error");
              getGuestShoppingCartList(false);
              setstep(step+1);
              setstep(step);
            }else{
              
            
              Swal.fire(response.data.message, "", "warning");
              getGuestShoppingCartList(false);
              setstep(step+1);
              setstep(step);
             
              // this.props.updateCartCount('5');
              
            }
          })
          .catch(function (error) {
            // console.log(error.statusCode);
          });
        // console.log(event);
      } else if (localStorage.getItem("token") !== undefined && value !== "") {
        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        axios
          .put(
            globalData.apiUrl + `/api/cart`,
            {
              id: id,
              quantity: value,
            },
            config
          )
          .then((response) => {
            if(typeof response.data.data.cartList !== "undefined" && response.data.data.cartList.length > 0 )
            {
              // console.log(response.data.data);
              setcartList([...response.data.data.cartList]);
              setsubtotal(response.data.data.total);
              settotal(response.data.data.totalPayableAmount);
              Swal.fire({
                title:response.data.message,
                showConfirmButton: false,
                icon:"success",
                timer: 3000,
                timerProgressBar: true,
              });
              setstep(step+1);
              setstep(step);
              // Swal.fire(response.data.message, "", "success");
  
            }else{
              // console.log(response.data.data);
              // props.updateCartCount(response.data.data.countCartItems);
              
              Swal.fire(response.data.message, "", "error");
              // this.props.updateCartCount('5');
              getShoppingCartList(false);
              setstep(step+1);
              setstep(step);
            }
          })
          .catch(function (error) {
            // console.log(response);
          });
        // console.log(event);
      }
     
    },1000);
    
   
  };

  const getData = () => {
    axios
      .get(globalData.apiUrl + "/api/getIpaddress", {})
      .then((result) => {
        setip(result.data.data);
      })
      .catch((error) => {});
  };

  const removeCart = (id, event) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((confirmed) => {
      // console.log(confirmed);
      if (confirmed.value) {
        if (localStorage.getItem("token")) {
          axios
            .delete(globalData.apiUrl + `/api/cart/${id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((response) => {
              getShoppingCartList(false);
              // window.location.reload()
              Swal.fire({
                title:response.data.message,
                showConfirmButton: false,
                icon:"success",
                timer: 3000,
                timerProgressBar: true,
              });
              setstep(step+2);
              setstep(step);
              // Swal.fire(response.data.message, "", "success");
            })
            .catch(function (error) {
              // console.log(error)
            });
        } else {
          axios
            .delete(
              globalData.apiUrl +
                `/api/cart/${id}?paramId=${cookies.get("guesttoken")}`,
              {}
            )
            .then((response) => {
              // console.log('ddddddddddddddddddddddddddddd');
              getGuestShoppingCartList(false);
              // window.location.reload()
              Swal.fire({
                title:response.data.message,
                showConfirmButton: false,
                icon:"success",
                timer: 3000,
                timerProgressBar: true,
              });
              setstep(step+2);
              setstep(step);
              // Swal.fire(response.data.message, "", "success");
            })
            .catch(function (error) {
              // console.log(error)
            });
        }
      } else {
      // Swal.fire("You cancel", "", "error");ste
      }
    });
  };

  // console.log(errors)
  // console.log(formData);
  // javascript switch case to show different form in each step
  switch (step) {
    
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      // console.log(props);
      return (
        <div className="page-content">
          <div id="checkout" className="bg-dark">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 text-center mt-3 mb-2">
                  <div className="card px-0 for-p-5 pb-0 mt-3 mb-3">
                    <h2 id="heading">Basket</h2>
                    <form id="msform">
                      <ul id="progressbar">
                        <li className="active" id="account">
                          <strong>Transaction Details </strong>
                        </li>
                        <li id="personal">
                          <strong>Billing Details</strong>
                        </li>
                        <li id="payment">
                          <strong>Payment Methods</strong>
                        </li>
                      </ul>
                      <StepOne
                        nextStep={nextStep}
                        gotoprevious={gotoprevious}
                        handleFormData={handleInputData}
                        removeCart={removeCart}
                        handleChange={handleChange}
                        values={formData}
                        cartlist={cartList}
                        total={total}
                        subtotal={subtotal}
                        isInstallment={isInstallmentValue}
                        outofstockPackageQuantity={isSingleRoomsPackagesSoldout}

                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 2:
      return (
        <div className="page-content">
          <div id="checkout" className="bg-dark">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 text-center mt-3 mb-2">
                  <div className="card px-0 for-p-5 pb-0 mt-3 mb-3">
                    <h2 id="heading">Checkout</h2>
                    <form id="msform">
                      <ul id="progressbar">
                        <li className="active" id="account">
                          <strong>Transaction Details </strong>
                        </li>
                        <li className="active" id="personal">
                          <strong>Billing Details</strong>
                        </li>
                        <li id="payment">
                          <strong>Payment Methods</strong>
                        </li>
                      </ul>
                      <StepTwo
                        nextStep={nextStep}
                        prevStep={prevStep}
                        userData={user}
                        handleInputData={handleInputData}
                        values={formData}
                        showLogin = {props.showLogin}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    // Only formData is passed as prop to show the final value at form submit
    case 3:
      return (
        <div className="page-content">
          <div id="checkout" className="bg-dark">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 text-center mt-3 mb-2">
                  <div className="card px-0 for-p-5 pb-0 mt-3 mb-3">
                    <h2 id="heading">Checkout</h2>
                    <form id="msform">
                      <ul id="progressbar">
                        <li className="active" id="account">
                          <strong>Transaction Details </strong>
                        </li>
                        <li className="active" id="personal">
                          <strong>Billing Details</strong>
                        </li>
                        <li className="active" id="payment">
                          <strong>Payment Methods</strong>
                        </li>
                      </ul>
                      <Final
                        prevStep={prevStep}
                        ipaddress={ip}
                        handleInputData={handleInputData}
                        isInstallment={isInstallmentValue}
                        values={formData}
                        firstStep={firstStep}
                        paymentHiddenRef={paymentHiddenRef}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    // default case to show nothing
    default:
      return <div className="App"></div>;
  }
}

const mapStateToProps = (state) => {
  // console.log(state, "jjjjjjjjjjjjjjj")
  return {
    showLogin: state.show_login,
    cartCount: state.cart_count,
    hideAlert: state.alert_show,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (user) => dispatch({ type: "USER", value: user }),
    hideLogin: () => dispatch({ type: "LOGIN_CONTROL", value: false }),
    hidesweetalert: ( (count) => dispatch({type: 'HIDE_SWEETALERT', value: count})),
    updateCartCount: ( (count) => dispatch({type: 'CART_COUNT', value: count})),
    updateCartQuantity: (quantity) => dispatch({ type: "CART_ITEM_QUANTITY", value: quantity }),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

